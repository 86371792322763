import { useMemo, useState } from "react";

export default function useDocumentHistory(init) {
  const [links, setLinks] = useState([init]); // Used to store history of all links
  const [index, setIndex] = useState(0); // Index of current state within `links`
  const link = useMemo(() => links[index], [links, index]); // Current state
  const setLink = (value) => {
    // If link has not changed, return to avoid triggering a re-render
    if (link && (link.guid === value.guid)) {
      return;
    }
    const copy = links.slice(0, index + 1); // This removes all future (redo) links after current index
    copy.push(value);
    setLinks(copy);
    setIndex(copy.length - 1);
  };
  // Clear all link history
  const resetLink = (init) => {
    setIndex(0);
    setLinks([init]);
  };
  // Allows you to go back (undo) N steps
  const goBack = (steps = 1) => {
    setIndex(Math.max(0, Number(index) - (Number(steps) || 1)));
  };
  // Allows you to go forward (redo) N steps
  const goForward = (steps = 1) => {
    setIndex(Math.min(links.length - 1, Number(index) + (Number(steps) || 1)));
  };
  return {
    link,
    setLink,
    resetLink,
    index,
    lastIndex: links.length - 1,
    goBack,
    goForward,
  };
}
