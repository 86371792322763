import { SpelExpressionEvaluator } from 'spel2js';

// Largely based on
// https://github.com/spring-projects/spring-framework/blob/main/spring-expression/src/main/java/org/springframework/expression/common/TemplateAwareExpressionParser.java

function compileExpressions(expressionString) {
	let expressions = [];
	const prefix = '#{';
	const suffix = '}';
	let startIdx = 0;

	while (startIdx < expressionString.length) {
		var prefixIndex = expressionString.indexOf(prefix, startIdx);
		if (prefixIndex >= startIdx) {
			// an inner expression was found - this is a composite
			if (prefixIndex > startIdx) {
				expressions.push(expressionString.substring(startIdx, prefixIndex));
			}
			var afterPrefixIndex = prefixIndex + prefix.length;
			var suffixIndex = skipToCorrectEndSuffix(suffix, expressionString, afterPrefixIndex);
			if (suffixIndex === -1) {
				throw new Error(
						"No ending suffix '" + suffix + "' for expression starting at character " +
						prefixIndex + ": " + expressionString.substring(prefixIndex));
			}
			if (suffixIndex === afterPrefixIndex) {
				throw new Error(
						"No expression defined within delimiter '" + prefix + suffix +
						"' at character " + prefixIndex);
			}
			var expr = expressionString.substring(prefixIndex + prefix.length, suffixIndex);
			expr = expr.trim();
			if (!expr) {
				throw new Error(
						"No expression defined within delimiter '" + prefix + suffix +
						"' at character " + prefixIndex);
			}
			expressions.push(SpelExpressionEvaluator.compile(expr));
			startIdx = suffixIndex + suffix.length;
		}
		else {
			// no more ${expressions} found in string, add rest as static text
			expressions.push(expressionString.substring(startIdx));
			startIdx = expressionString.length;
		}
	}

	return expressions;
}

function isSuffixHere(expressionString, pos, suffix) {
	let suffixPosition = 0;
	for (let i = 0; i < suffix.length && pos < expressionString.length; i++) {
		if (expressionString.charAt(pos++) !== suffix.charAt(suffixPosition++)) {
			return false;
		}
	}
	if (suffixPosition !== suffix.length) {
		// the expressionString ran out before the suffix could entirely be found
		return false;
	}
	return true;
}

function skipToCorrectEndSuffix(suffix, expressionString, afterPrefixIndex) {
	// Chew on the expression text - relying on the rules:
	// brackets must be in pairs: () [] {}
	// string literals are "..." or '...' and these may contain unmatched brackets
	let pos = afterPrefixIndex;
	const maxlen = expressionString.length;
	const nextSuffix = expressionString.indexOf(suffix, afterPrefixIndex);
	if (nextSuffix === -1) {
		return -1; // the suffix is missing
	}
	let stack = [];
	while (pos < maxlen) {
		if (isSuffixHere(expressionString, pos, suffix) && stack.length === 0) {
			break;
		}
		var ch = expressionString.charAt(pos);
		switch (ch) {
			case '{':
			case '[':
			case '(':
				stack.push(new Bracket(ch, pos));
				break;
			case '}':
			case ']':
			case ')':
				if (stack.length === 0) {
					throw new Error("Found closing '" + ch +
							"' at position " + pos + " without an opening '" +
							theOpenBracketFor(ch) + "'");
				}
				var p = stack.pop();
				if (!p.compatibleWithCloseBracket(ch)) {
					throw new Error("Found closing '" + ch +
							"' at position " + pos + " but most recent opening is '" + p.bracket +
							"' at position " + p.pos);
				}
				break;
			case '\'':
			case '"':
				// jump to the end of the literal
				var endLiteral = expressionString.indexOf(ch, pos + 1);
				if (endLiteral === -1) {
					throw new Error(
							"Found non terminating string literal starting at position " + pos);
				}
				pos = endLiteral;
				break;
		}
		pos++;
	}
	if (stack.length > 0) {
		const p = stack.pop();
		throw new Error("Missing closing '" +
				theCloseBracketFor(p.bracket) + "' for '" + p.bracket + "' at position " + p.pos);
	}
	if (!isSuffixHere(expressionString, pos, suffix)) {
		return -1;
	}
	return pos;
}

var Bracket = function(bracket, pos) {
	this.bracket = bracket;
	this.pos = pos;

	this.compatibleWithCloseBracket = function (closeBracket) {
		if (this.bracket === '{') {
			return closeBracket === '}';
		}
		else if (this.bracket === '[') {
			return closeBracket === ']';
		}
		return closeBracket === ')';
	};
}

function theOpenBracketFor(closeBracket) {
	if (closeBracket === '}') {
		return '{';
	}
	else if (closeBracket === ']') {
		return '[';
	}
	return '(';
}

function theCloseBracketFor(openBracket) {
	if (openBracket === '{') {
		return '}';
	}
	else if (openBracket === '[') {
		return ']';
	}
	return ')';
}

let templateAwareSpelExpressionEvaluator = {};

templateAwareSpelExpressionEvaluator.compile = function(expressionString) {
	const compiledExpressions = compileExpressions(expressionString);
	return {
		eval: function (context, locals) {
			const evalResult = compiledExpressions
				.map(ce => (typeof(ce) === 'string') ? ce : ce.eval(context, locals))
				.join('');

			return evalResult;
		},
		_compiledExpressions: compiledExpressions
	};
};

templateAwareSpelExpressionEvaluator.eval = function(expressionString, context, locals) {
	let evalResult = templateAwareSpelExpressionEvaluator.compile(expressionString).eval(context, locals);
	return evalResult;
};

export { templateAwareSpelExpressionEvaluator as default };