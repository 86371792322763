import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { renderToString } from 'react-dom/server';
import { urlParam } from '../../config/utils';
import SequentialList from 'react-sequential-list';

import {
  Button,
  Card,
  Row,
  Col,
  Space,
  Input,
  Menu,
  Dropdown,
  Form,
  Tag,
  Popover,
  Tooltip,
  Modal,
  Typography, List,
  Avatar,
} from 'antd';
import { MapContainer as Map, TileLayer, Marker, Popup } from 'react-leaflet';
import {
  ReloadOutlined,
  CommentOutlined,
  GlobalOutlined,
  SendOutlined,
  MessageOutlined,
  CloseOutlined,
  BellFilled,
  QuestionCircleOutlined,
  ArrowLeftOutlined,
  SettingOutlined,
  RightOutlined,
  LeftOutlined,
  PaperClipOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import {
  startSession,
  sendServerMessage,
  sendUserMessage,
  changeLanguage,
  resetSession,
  toggleDebugMode,
  sendAgentMessage,
  startLivechat,
  closeLivechat,
  sendCommand,
  setupURL,
  loadDocument,
  executeNativeMessage,
  startDebugMode
 } from '../../actions/bot-actions';
import useDocumentHistory from '../../actions/use-document-history';
import Message from "../message";
import AddFeedbackModal from '../modals/add-feedback-modal';
import AddUploadModal from '../modals/add-file-modal';
import { LANGUAGE_LIST } from '../../config/utils';
import RocketChat from './rocket-chat';
import Sematell from './sematell';
import BackendChat from './backend-chat';
import Notifications from './notifications';
import Languages from '../../config/lang';
import L from 'leaflet';
import icon from './marker.png';
import { SpelExpressionEvaluator } from 'spel2js';
import TemplateAwareSpelExpressionEvaluator from './TemplateAwareSpelExpressionEvaluator';
import './bot-doc-viewer.less';

const DefaultIcon = new L.Icon({
  iconUrl: icon,
  iconSize: new L.Point(21, 25),
});

L.Marker.prototype.options.icon = DefaultIcon;

const { Text } = Typography;
let notifications;

const Bot = (props) => {
  const hasWindow = typeof window !== 'undefined';
  const [fullHeight, setFullHeight] = useState(0);
  const [ DebugModeCounter , setDebugModeCounter] = useState(0);
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ isUploadModalVisible, setIsUploadModalVisible ] = useState(false);
  const {
    link: docLink,
    setLink: setDocLink,
    resetLink: resetDocLink,
    index: docIndex,
    lastIndex: lastDocIndex,
    goBack: goDocBack,
    goForward: goDocForward
  } = useDocumentHistory(false);
  const [ SelectedMessage, setSelectedMessage ] = useState(false);

  const [ error, setError ] = useState(false);

  const BotReducer = useSelector(state => state.BotReducer);
  const {
    config,
    config: {
      _supportedLanguages,
      chatEnabled,
      fallbackLanguage,
      name,
      allowSubscriptions,
      showHelpIcon,
      helpDocGuid,
      textToSpeechEnabled,
      autoPlay,
      textToSpeech
    },

    bubbles,
    audios,
    topic,
    debug_mode,
    interaction,
    interaction: {
      inputHint,
      inputFieldActive,
      inputFieldVisible,
      showResultCount,
      inputType,
      //allowAutoComplete, not used yet in the old version, so always true
      allowOptionFiltering,
    },
    request_counter,
    default_language,
    session_id,
    debug_data,
    vars,
    lastNativeMessagingResult,
    scroll_id

  } = BotReducer;

  const [ QueryForm ] = Form.useForm();
  const QueryInput = useRef(null);
  const [ chat, setChat ] = useState(null);
  const [ chatStarted, setChatStarted ] = useState(false);
  const [ subscribed, setSubscribed ] = useState(false);
  const [ helpMode, setHelpMode ] = useState(false);
  const [ docRenderer, setDocRenderer ] = useState(false);
  const [ viewContent, setViewContent ] = useState(false);
  const [ pdf, setPdf ] = useState(null);
  const [ showLightBox, setShowLightBox] = useState(false);
  const [ lightboxImage, setLightboxImage] = useState(false);
  const [ documents, setDocuments] = useState([]);
  const [ userInput, setUserInput] = useState(false);
  const [ title, setTitle ] = useState([name]);
  const [ optionsPage, setOptionsPage ] = useState(1);
  const [ loading, setLoading ] = useState(false);
  const [ executing, setExecuting ] = useState(false);
  const [ embeddedKFirst, setEmbeddedKFirst ] = useState(urlParam('embeddedKFirst'));
  const [optionsHeight, setOptionsHeight] = useState(0);
  const [currentAudioWithStatus, setCurrentAudioWithStatus] = useState({audio: false, playing: false})
  const [file, setFile] = useState(false);
  const [agentTyping, setAgentTyping] = useState(false);
  const [showInEffect, setShowInEffect] = useState(true);
  const messagesEnd = useRef(null);

  const getTerm = function(term, language){
    const chatTranslations = config.chatTranslations;
    if(chatTranslations && chatTranslations[language]) {
      return chatTranslations[language][term] || term;
    } else if(chatTranslations && !chatTranslations[language] && chatTranslations["en"]) {
      return chatTranslations["en"][term] || term;
    } else if(chatTranslations && !chatTranslations[language] && chatTranslations["de"]) {
      return chatTranslations["de"][term] || term;
    }
  };

  const optionsHolder = useCallback(node => {
    if (node !== null) {
      setOptionsHeight(node.getBoundingClientRect().height);
      autoScroll();
    }
  }, []);

  const autoScroll = (position) => {
    position = position || 0;
    if (request_counter > 1) {
      if (scroll_id) {
        const ele = document.getElementById(scroll_id);
        if (ele) {
          position = ele.offsetTop - 100;
        }
      } else {
        position = document.getElementById('hidden-holder').offsetTop;
      }
    }

    if (messagesEnd && messagesEnd.current) {
      messagesEnd.current.scrollTo({
        top: position,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        setFullHeight(vh);
      }
      window.addEventListener('resize', handleResize);
      setTimeout(() => {
        handleResize();
      });

      return () => window.removeEventListener('resize', handleResize);

    }
  }, []);

  useEffect(() => {
    if (docLink) {
      fetchDocument(docLink.guid, docLink.externalAgentKey);
    }
  }, [docLink]);

  useEffect(() => {
    if (topic) {
      setTitle([BotReducer.topic]);
    } else {
      setTitle([BotReducer.config.name]);
    }
  }, [BotReducer.config.name, BotReducer.topic]);

  useEffect(() => {
    if (DebugModeCounter === 10 && config.allowDebug) {
      toggleDebugMode();
    }
  }, [DebugModeCounter, config.allowDebug]);



  useEffect(() => {
    if (!debug_mode && config.allowDebug) {
      startDebugMode();
    }
  }, [config, debug_mode]);

  useEffect(() => {
    if (embeddedKFirst === 'true' && config.selector) {
      document.getElementById(config.selector).addEventListener('closeChat', closeChat);
      document.getElementById(config.selector).addEventListener('closeDocument', closeDocument);
      document.getElementById(config.selector).addEventListener('resetBot', onReset);
      document.getElementById(config.selector).addEventListener('startChat', startChat);
      document.getElementById(config.selector).addEventListener('openHelp', openHelp);
      document.getElementById(config.selector).addEventListener('subscribeNotification', subscribe);
      document.getElementById(config.selector).addEventListener('unsubscribeNotification', unsubscribe);
    }
  }, [embeddedKFirst, config.selector]);

  useEffect(() => {
    autoScroll();
  }, [BotReducer.bubbles]);

  useEffect(() => {
    if (audios && autoPlay && textToSpeechEnabled) {
      if (audios[0].audio) {
        setCurrentAudioWithStatus({ audio: audios[0].audio, playing: true });
        audios[0].audio.play().catch(error => {
          console.error(error);
          setCurrentAudioWithStatus({ audio: false, playing: false });
        });
      }

      audios.forEach((audi)=>{
        if (audi.audio) {
          audi.audio.onended = function () {
            setCurrentAudioWithStatus({ audio: false, playing: false });
            playNext(audi.index);
           }
        }
      });
    }

  }, [BotReducer.audios, autoPlay]);

  const playNext = (index) => {
    if (audios.length > index + 1){
      if ( audios[index + 1].audio) {
        audios[index + 1].audio.play();
        setCurrentAudioWithStatus({ audio: audios[index + 1].audio, playing: true });

      } else {
        setCurrentAudioWithStatus({ audio: false, playing: false });
      }

    }

  }

  useEffect(() => {
    if (BotReducer && BotReducer.config && BotReducer.config.key) {
      let lang = false;
      if (config.languageDetectionMethod === 'browser') {
        let userLang;
        if (navigator.languages && navigator.languages.length) {
          userLang = navigator.languages[0];
        } else {
          userLang = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'de';
        }
        if (userLang) {
          if (userLang.indexOf('-') !== -1) { userLang = userLang.split('-')[0]; }

          if (userLang.indexOf('_') !== -1) { userLang = userLang.split('_')[0]; }

          userLang = parseIETFLanguageTag(userLang);
          const idx = _supportedLanguages.indexOf(userLang.language);
          if (idx === -1) {
            if (fallbackLanguage === 'default') {
              lang = (_supportedLanguages.length > 0) ? _supportedLanguages[0] : 'de';
            } else {
              lang = fallbackLanguage;
            }
          } else {
            lang = userLang.language;
          }
          changeLanguage(lang);
        }
      }

      if (config.languageDetectionMethod === 'url') {
        lang = urlParam(config.languageQueryParameter);
        const idx = _supportedLanguages.indexOf(lang);
        if (idx === -1) {
          console.log(lang + 'Language set is not supported');
          if (fallbackLanguage === 'default') {
            lang = (_supportedLanguages.length > 0) ? _supportedLanguages[0] : 'de';
          } else {
            lang = fallbackLanguage;
          }
        } else {
          lang = _supportedLanguages[idx];
        }
        changeLanguage(lang);
      }

      if (config.key) {
        const url = `${config.baseURL}kbot-api/interact/${config.key}/widget:Default`;
        setupURL(url);
        if (vars && Object.keys(vars).indexOf('query') !== -1){


          sendUserMessage(vars.query, () => {
            // Use startSession request so that leadbot start by checking member availability through script works
            // Together with query
            setLoading(true);
            startSession({ ...BotReducer, default_language: lang }, (response) => {
              setLoading(false);
            });
            focusOnInputField();
          });
        } else {
          startSession({ ...BotReducer, default_language: lang }, () => { });
        }

        if (config.key) {
          notifications = Notifications(config);
          notifications.init(`${config.baseURL}kbot-api/interact/${config.key}/widget:Default`);
          notifications.checkSubscription((flag) => {
            setSubscribed(flag);
          });
        }
      }
    }

  }, [BotReducer.config.key]);

  useEffect(() => {
    if (chatEnabled) {
      if (config.chatType === 'RocketChat') {
        setChat(RocketChat({
          ...config,
          sendAgentMessage,
          sendServerMessage,
          BotReducer,
          getTerm
        }));
      } else if (config.chatType === 'Sematell') {
        setChat(Sematell({
          ...config,
          sendAgentMessage,
          sendServerMessage,
          BotReducer,
          getTerm
        }));
      } else {
        setChat(BackendChat({
          ...config,
          sendAgentMessage: props.sendAgentMessage,
          sendServerMessage: props.sendServerMessage,
          BotReducer,
          setAgentTyping,
          getTerm
        }));
      }

    }

  }, [chatEnabled]);

  useEffect(() => {
    if (interaction && interaction.inputOptions) {
      setOptionsPage(1);
    };
  }, [interaction.inputOptions]);


  useEffect(() => {
    if (userInput === '' || !userInput) {
      setOptionsPage(1);
    }
  }, [userInput]);

  useEffect(() => {
    if (session_id) {
      const url = `${config.baseURL}kbot-api/interact/${config.key}/widget:Default`;
      setupDocRenderer(config.baseURL, url, default_language);
    }
  }, [config, session_id, default_language]);

  const parseIETFLanguageTag = function (tag) {
    var empty = '';
    if (typeof tag === 'string' || tag instanceof String) {
      return {
        language: tag,
        extlang: empty,
        script: empty,
        region: empty
      }
    } else {
      return {
        language: tag[1] || empty,
        extlang: tag[2] || empty,
        script: tag[3] || empty,
        region: tag[4] || empty
      }
    }
  };

  const inIframe  = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
  }

  const setupDocRenderer = (baseURL, url, default_language) => {
    const docConfig = new window.kcDocRenderer.BasicConfiguration(
      true,
      `${url}/v2/documents/dialog`,
      `${url}/v2/documents`,
      `${url}/v2/documents`,
      `apikey=${encodeURIComponent(config.key)}`,
      `${url}/v2/documents`
    );
    docConfig.setSendQuestionnaireToValueStore(config.writeQuestionnaireToKVS);
    docConfig.setValueStoreSessionId(session_id);
    docConfig.setValueStoreURL(`${baseURL}kbot-valuestore/api/v1/sessions`);

    docConfig.includeReferencesUpToLevel = 3;
    docConfig.setDefaultLanguage(default_language);
    docConfig.embeddedInKFirst = true;
    docConfig.authenticationURLFragment = `apikey=${encodeURIComponent(config.key)}`;

    if (docConfig.setRenderSolutionsLink) {
      docConfig.setRenderSolutionsLink(false);
    }

    const documentRenderer = new window.kcDocRenderer.DocumentRenderer(docConfig);

    documentRenderer.registerDocumentType(
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.STATIC_DOCUMENT_TYPE,
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.STATIC_DOCUMENT_TYPE
    );
    documentRenderer.registerDocumentType(
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.CONDITIONAL_DOCUMENT_TYPE,
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.CONDITIONAL_DOCUMENT_TYPE
    );
    documentRenderer.registerDocumentType(
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE,
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE
    );
    documentRenderer.registerDocumentType(
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE,
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE
    );
    documentRenderer.registerDocumentType(
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.META_DOCUMENT_TYPE,
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.META_DOCUMENT_TYPE
    );

    documentRenderer.registerDefaultTemplate(
      window.metadocument_template,
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.META_DOCUMENT_TYPE
    );
    documentRenderer.registerDefaultTemplate(
      window.static_template,
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.STATIC_DOCUMENT_TYPE
    );
    documentRenderer.registerDefaultTemplate(
      window.conditional_template,
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.CONDITIONAL_DOCUMENT_TYPE
    );
    documentRenderer.registerDefaultTemplate(
      window.questionnaire_template,
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE
    );
    documentRenderer.registerDefaultTemplate(
      window.errorpattern_template,
      window.kcDocRenderer.SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE
    );


    documentRenderer.configuration.authenticationURLFragment = `apikey=${encodeURIComponent(config.key)}`;

    setDocRenderer(documentRenderer);
  };

  const scrollToBottom = () => {
    // scroll.scrollToBottom();
  };

  const onLanguageChange = (e) => {
    if (default_language === e.key) {
      return;
    }
    closeChat(true);
    resetSession({ ...BotReducer, default_language: e.key, resetSource: 'languangeChange' }, () => {
      setFile(false);
      setDebugModeCounter(0);
      endAudioPlay();
    });
  };

  const endAudioPlay = () => {
    if (currentAudioWithStatus.audio && currentAudioWithStatus.playing) {
      currentAudioWithStatus.audio.pause();
      setCurrentAudioWithStatus({ audio: false, playing: false });
    }
  }
  const focusOnInputField = () => {
    if (QueryInput.current) {
      QueryInput.current.focus();
    }
  };

  const startChat = () => {
    if (!chatStarted) {
      setChatStarted(true);
      chat.init(default_language, session_id, (reason) => {
        setChatStarted(false);
        chat.close(false, () => {
          closeLivechat();
          sendCommand('endLiveChat', BotReducer);
        });
      });
      const defaultHint = config.hintTranslations[default_language || 'en' || 'de'];
      startLivechat(defaultHint || '');
    }
  };

  const openHelp = () => {
    openDocument(helpDocGuid);
  };

  const closeChat = () => {
    if (chatStarted) {
      setChatStarted(false);
      chat.close(true, () => {
        closeLivechat();
      });
    }
  };

  const subscribe = () => {
    notifications.subscribePushNotifications();
    setSubscribed(true);
  };

  const unsubscribe = () => {
    notifications.unsubscribePushNotifications();
    setSubscribed(false);
  };

  const getSelfHealingCommand = (bubble) => {
    if (!bubble.metadata || !bubble.metadata.kc || !bubble.metadata.kc.data_key) {
      return null;
    }

    var result = bubble.metadata.kc.data_key;
    return result;
  };

  const determineInputOption = (intera, resultValue) => {
    if (intera && intera.inputOptions) {
      var filteredOptions = intera.inputOptions.filter(io => io.metadata && io.metadata.expr);

      if (filteredOptions.length > 0) {
        const locals = {
          result: resultValue
        };

        for (const option of filteredOptions) {
          const compiledExpression = SpelExpressionEvaluator.compile(option.metadata.expr);
          const evalRes = compiledExpression.eval({}, locals);

          if (evalRes === true) {
            return option;
          }
        }
      }
    }
  };

  const evaluateSelfHealing = (response) => {
    if (response) {
      const data = response.data;

      if (data) {
        const dataResponse = data.response;

        if (dataResponse) {
          const payloads = dataResponse.payload;

          if (payloads && payloads.length > 0) {
            const bubbles = payloads[0].conversation.bubbles;

            if (bubbles && bubbles.length > 0) {
              const shCommand = getSelfHealingCommand(bubbles[0]);

              if (shCommand) {
                setExecuting(true);

                executeNativeMessage(shCommand, (res, isError) => {
                  if (isError !== true && res.result.HasValue) {
                    const option = determineInputOption(payloads[0].interaction, res.result.Value);

                    sendServerMessage(option.value, BotReducer, null, null, () => {
                      setExecuting(false);
                    });
                  }
                  else {
                    setExecuting(false);
                  }
                });
              }
            }
          }
        }
      }
    }
  };

  const onOptionClick = (option) => {
    QueryForm.resetFields();
    setUserInput(false);
    if (
      option.value.indexOf('#cmd://openChat') === 0 ||
      option.value.indexOf('#cmd://openAgent') === 0
    ){
      sendUserMessage(option.label, () => {
        sendCommand('startLiveChat', BotReducer, () => {
          startChat();
        });
      });
    } else {
      sendUserMessage(option.label, () => {
        setLoading(true);
        sendServerMessage(option.value, BotReducer, null, null, (response) => {
          setLoading(false);
          evaluateSelfHealing(response);
        });
        focusOnInputField();
      });
    }
  };

  const onReset = () => {
    setShowInEffect(false);
    resetSession({ ...BotReducer, default_language, resetSource: "resetButton" }, () => {
      setFile(false);
      setUserInput(false);
      endAudioPlay();
      autoScroll();
      setShowInEffect(true);
    });
  };

  const onPressEnter = (e) => {
    setUserInput(false);
    if (userInput.length > 0) {
      if (((allowOptionFiltering && interaction.inputType === 'vertical_select') ||
          interaction.inputType === 'text')) {
        const options = filterOptions(interaction.inputOptions);
        if (options.length === 1) {
          onOptionClick(options[0]);
          QueryForm.resetFields();
          e.preventDefault();
        } else {
          QueryForm.submit();
          e.preventDefault();
        }
        setDebugModeCounter(0);
      }

    } else {
      setDebugModeCounter(DebugModeCounter + 1);
      e.preventDefault();
    }
  }

  const submitForm = (e) => {
    QueryForm.submit();
  };

  const onUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const onChange = (e) => {
    if (chatStarted && chat && chat.sendUserTypingEvent) {
      chat.sendUserTypingEvent();
    }
  };

  const sendMessage = (values) => {
    setUserInput(false);
    setError(false);
    let userMessage = values.query;
    let serverMessage = values.query;
    if (file && file.name) {
      userMessage = `${userMessage} <a href="#" data-kbot-uploaded-file="#">${file.name}</a>`;
      serverMessage = `${serverMessage} ${file.name}`;
    }
    sendUserMessage(userMessage, () => {
      QueryForm.resetFields();
      if (chatStarted) {
        sendServerMessage(values.query, BotReducer, 'live-chat-user', null, () => {
          if (config.chatType === 'RocketChat') {
            chat.RocketChatSendMessage(values.query);
          } else {
            chat.sendChatMessage(values.query);
          }
        });
      } else {
        setLoading(true);
        sendServerMessage(serverMessage, BotReducer, null, file, (response) => {
          setLoading(false);
          evaluateSelfHealing(response);
          setFile(false);
        });
      }
      focusOnInputField();
      scrollToBottom();
    });
  };

  const attachDocumentEvents = (data, key) => {
    const sections = document.querySelectorAll('.docSectionHeader');
    const sectionsBodies = document.querySelectorAll('.docSectionBody');
    const links = document.querySelectorAll('a');
    const debug = document.querySelector('.debug-btn-doc');
    const images = document.querySelectorAll('img');

    if (debug) {
      debug.removeEventListener('click', ()=> {}, true);
      debug.addEventListener('click', () => showFeedbackModal({'metadata' : {'docid': data.id}}));
    }
    sectionsBodies.forEach(item => {
      item.classList.toggle('hidden');
    });
    sections.forEach(item => {
      item.removeEventListener('click', ()=> {}, true);
      item.addEventListener('click', ()=> {
        item.nextElementSibling.classList.toggle('hidden');
        item.classList.toggle('sectionOpened');
        item.parentNode.classList.toggle('sectionOpend');
      });
    });
    images.forEach(item => {
      item.removeEventListener('click', ()=> {}, true);
      item.addEventListener('click', ()=> {
        setLightboxImage(item.src);
        setShowLightBox(true);
      });
    });

      links.forEach(item => {
        item.removeEventListener('click', ()=> {}, true);
        item.addEventListener('click', (e)=> {

        if (item.hasAttribute('href')) {
          const hrefId = item.getAttribute('href');
          if (hrefId && hrefId.startsWith('#') && item.classList.length === 0) {
            //item.addEventListener('click', () => {
              const referredEle = document.getElementById(hrefId.replace('#',''));
              if(referredEle) referredEle.scrollIntoView();
              e.preventDefault();
           // })
          }
          if (item.dataset.kfirstDocAnchorSource) {
            const referredEle = document.getElementById(item.dataset.kfirstDocAnchorSource);
              if(referredEle) referredEle.scrollIntoView();
              e.preventDefault();
          }
        }
        if (item.dataset.kbotDoc && !item.dataset.kbotAtt) {
          openDocument(item.dataset.kbotDoc.replace('guid:', ''), key);
          e.preventDefault();
        }

        // If anchor has chater id, it needs to scroll to a docSection div with this id
        // TODO check why scrolling doesn't always work.
        const tocId = item.dataset.kbotToc;
        const kbotChapterId = item.dataset.kbotChapterId;
        if (kbotChapterId) {
          const chapterSection = document.getElementById(kbotChapterId);
          if (chapterSection) {
            chapterSection.scrollIntoView();
            e.preventDefault();
          }
        }
        if (tocId) {
          const tocSection = document.getElementById(tocId);
          if (tocSection) {
            tocSection.scrollIntoView();
            e.stopPropagation();
            e.preventDefault();
          }
        }
      });
    });
  };

  const fetchDocument = (guid, externalAgentKey) => {
    const url = `${config.baseURL}kbot-api/interact/${config.key}/widget:Default`;
    const agentApiKey = externalAgentKey ? encodeURIComponent(externalAgentKey) : encodeURIComponent(config.key);
    if (config && config.useDocRenderer) {
      const docUrl = `${url}/v2/documents/${encodeURIComponent(guid)}?apikey=${agentApiKey}&contentLanguage=${default_language}&additionalData=references=true&additionalData=referencedDocs=true&additionalData=withGraph=true&additionalData=includeReferencesUpToLevel=2`;
      loadDocument(docUrl, (res, error) => {
        if (error) {
          setError(res.toString());
          return;
        }
        setDocLink({ guid, externalAgentKey });
        let rendered = docRenderer.renderWithDefaultTemplate(res.data);
        rendered = renderToString(renderDocTitle(res.data.title)) + rendered;
        const debugButton = renderToString(renderFeedbackIconDoc({'metadata' : {'docid': res.data.id}}));
        rendered = rendered + debugButton;
        setViewContent(rendered);
        docRenderer.getActiveDocumentEngine(res.data, docRenderer.configuration, true);

        if (document.getElementById('btn_reset')) {
          document.getElementById('btn_reset').value = 'Reset';
        }

        if (document.getElementById('btn_finish')) {
          document.getElementById('btn_finish').value = 'Finish';
          var submitButton = document.getElementById('btn_finish');
          submitButton.removeEventListener('click', ()=> {}, true);
          submitButton.addEventListener('click', closeDocument);
        }

        if (document.getElementById('btn_next')) {
          document.getElementById('btn_next').value = 'Next';
        }
        const referenceLinks = document.querySelectorAll('a.referenceLink.fa-file');
        referenceLinks.forEach(item => {
          if (!item.dataset.kbotDoc) {
            item.classList.add('hidden');
          }
        });
        attachDocumentEvents(res.data, externalAgentKey || config.key);

        setTimeout(() => {
          document.getElementById('bot-doc-viewer').scrollIntoView();
        });
      });
    }
  };

  const openDocument = (guid, externalAgentKey) => {
    setDocuments([...documents, guid]);
    setHelpMode(true);
    setDocLink({ guid, externalAgentKey })
  };

  const renderPdf = (title, url) => {
    setPdf({title: title, url: url});
  };

  const renderNotifications = () => {
    if (allowSubscriptions) {
      if (!subscribed) {
        return (
          <Tooltip placement="top" title={Languages.getTerm('header.news.button.title', default_language)}>
            <Button onClick={subscribe} type="link"><BellFilled /></Button>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip placement="top" title={Languages.getTerm('header.news.button.title', default_language)}>
            <Button onClick={unsubscribe} className="unsubscribed-btn" type="link"><BellFilled /></Button>
          </Tooltip>
        );
      }
    }
    return [];
  };

  const closeDocument = () => {
    setHelpMode(false);
    goDocBack();
    if (!docLink) {
      setTimeout(() => {
        document.getElementById('hidden-holder').scrollIntoView();
      }, 200);
    }
  };

  const closePdf = () => {
    setPdf(null);
    setTimeout(() => {
      document.getElementById('hidden-holder').scrollIntoView();
    }, 200);
  }

  const openImage = (url) => {
    setLightboxImage(url);
    setShowLightBox(true);
  };

  const renderResetOrClose = () => {
    if (chatEnabled && chatStarted) {
      return (
        <Tooltip placement="top" title={Languages.getTerm('header.chat.close.button.title', default_language)}>
          <Button onClick={closeChat} type="link"><CloseOutlined /></Button>
        </Tooltip>
      );
    }
    if (pdf && pdf.title) {
      return (
        <Tooltip placement="top" title={Languages.getTerm('header.close.button.title', default_language)}>
        <Button onClick={closePdf} type="link"><ArrowLeftOutlined /></Button>
        </Tooltip>
      );
    }
    if (docLink) {
      return (
        <Tooltip placement="top" title={Languages.getTerm('header.close.button.title', default_language)}>
        <Button onClick={closeDocument} type="link"><ArrowLeftOutlined /></Button>
        </Tooltip>
      );
    }
    return [
      <Tooltip placement="top" title={Languages.getTerm('header.reset.button.title', default_language)}>
        <Button onClick={onReset} type="link"><ReloadOutlined /></Button>
      </Tooltip>
    ];
  };

  const renderChat = () => {
    if (chatEnabled) {
      return (
        <Tooltip placement="top" title={Languages.getTerm('header.chat.button.title', default_language)}>
          <Button onClick={() => startChat()} type="link"><CommentOutlined /></Button>
        </Tooltip>
      );
    }
    return [];
  };

  const renderHelp = ( ) => {
    if (showHelpIcon) {
      return (
        <Tooltip placement="top" title={Languages.getTerm('header.help.title', default_language)}>
          <Button onClick={() => openHelp()} type="link"><QuestionCircleOutlined /></Button>
        </Tooltip>
      );
    }
    return [];
  };

  const renderTitle = () => {
    if (embeddedKFirst === 'true') {
      return null;
    }

    return [
      <Row gutter={16} >
        <Col span={6}>
          <Space>
            {renderResetOrClose()}
            {renderNotifications()}
          </Space>
        </Col>
        <Col
          span={12}
          className="bot-title"
          onClick={() => setDebugModeCounter(DebugModeCounter + 1)}
        >
          <Text className="bot-title-text" style={{ width: '100%' }} ellipsis={true}>{title[title.length - 1]}</Text>
        </Col>
        <Col span={6} className="bot-extra">
          <Space>
            {renderChat()}
            {renderHelp()}
            {_supportedLanguages && _supportedLanguages.length > 1 && (
              <Tooltip placement="top" title={Languages.getTerm('header.lang.chooser.title', default_language)}>
                <Dropdown
                  trigger={["click"]}
                  placement="bottomRight"
                  getPopupContainer={() => document.getElementById('pre-kbot-card')}
                  overlay={menu}
                >
                  <Button onClick={() => { }} type="link"><GlobalOutlined /></Button>
                </Dropdown>
              </Tooltip>
            )}
          </Space>
        </Col>
      </Row>,
      <div id="pre-kbot-card"></div>
    ];
  }

  const renderMessageMetaData = (message) => {
    const info = [];
    if (debug_mode && message.debug) {
      info.push(<div><Tag color="geekblue">{message.debug}</Tag></div>);
    }
    if (debug_data) {
      info.push(<div><Tag color="red">State: {debug_data.state}</Tag></div>);
    }
    if (debug_mode && message.metadata) {
      if (message.metadata.agentName) {
        info.push(<div><Tag color="red">AgentName: {message.metadata.agentName}</Tag></div>);
      }
      if (message.metadata.agentId) {
        info.push(<div><Tag color="red">AgentId: {message.metadata.agentId}</Tag></div>);
      }
      if (message.metadata.messageId) {
        info.push(<div><Tag color="orange">MessageId: {message.metadata.messageId}</Tag></div>);
      }
      if (message.metadata.messageType) {
        info.push(<div><Tag color="orange">MessageType: {message.metadata.messageType}</Tag></div>);
      }
      if (message.metadata.docid) {
        info.push(<div><Tag color="orange">DocId: {message.metadata.docid}</Tag></div>)
      }
    }
    return info;
  };

  const renderDebugPopover = (message) => {
    if (debug_mode && message.debug_mode && message.type === 'bot') {
      return (
        <Popover
          placement="right"
          title={Languages.getTerm('debug-info', default_language)}
          content={renderMessageMetaData(message)}
          trigger="click"
        >
          <Tag className="debug-btn" color="red">{Languages.getTerm('debug-info', default_language)}</Tag>
        </Popover>
      );
    }
  };

  const showFeedbackModal = (message) => {
    setSelectedMessage(message);
    setIsModalVisible(true);
  }

  const onClickFileButton = () => {
    if (file && file.name) {
      setFile(false);
    } else {
      setIsUploadModalVisible(true);
    }
  }

  const truncate = (str) => {
    return str.length > 6 ? str.substring(0, 5) + "..." : str;
}

  const renderFeedbackIcon = (message, isOption) => {
    // console.log(isOption && message.metadata.docid);
    if (debug_mode &&
      ((message.type === 'bot' && message.metadata.docid) ||
        (message.type === 'bot' && message.metadata.messageId && message.metadata.messageType) ||
        (isOption && message.metadata.messageId && message.metadata.messageType) ||
        (isOption && message.metadata.docid))) {

          if (isOption) {
            return (
              <Tag className="debug-btn" onClick={(e) => { e.stopPropagation(); showFeedbackModal(message) }} color="blue">
                <MessageOutlined />
              </Tag>
            );
          } else {
            return (
              <Tag className="debug-btn" onClick={() => showFeedbackModal(message)} color="blue"><MessageOutlined /></Tag>
            );
          }
    }
  };

  const renderDocTitle = (title) => {
    return (
      <div class="title">
        <h1>{title}</h1>
      </div>
    )
  }

  const renderFeedbackIconDoc = (message) => {
    if (debug_mode && message.metadata.docid) {
      return (
        <Button type="primary" className="debug-btn-doc" onClick={() => showFeedbackModal(message)}>Give Feedback</Button>
      );
    }
  };

  const renderMap = (message, index, position) => {
    return (
      <div className="map-holder">
        <Map
          center={position}
          style={{ height: "250px", width: "100%", overflow: "hidden" }}
          zoom={13}
          whenReady={autoScroll}
          whenCreated={autoScroll}
          tabindex="-1"
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
              Latitude: {position[0]} <br />
              Longitude: {position[1]} <br />
            </Popup>
          </Marker>
        </Map>
      </div>
    );
  };

  const getPatchedMessageContent = (messageContent, type) => {
    // Add some dummy audio part
    const audio = '<audio controls src=" " type="audio.mpeg"></audio>'
    if (!lastNativeMessagingResult) {
      if (config && config.textToSpeechEnabled) {
        messageContent = audio + messageContent;
      }

      return messageContent;
    }

    try {
      let evalResult = TemplateAwareSpelExpressionEvaluator.eval(messageContent, lastNativeMessagingResult, {});
      if (config && config.textToSpeechEnabled && type === 'bot') {
        evalResult = audio + evalResult;
      }
      return evalResult;
    }
    catch (err) {
      console.error('getPatchedMessageContent', err);
      return messageContent;
    }
  };

  const renderMessages = () => {


    return bubbles.map((message, index) => {
      return (
        <Message
          message={message}
          targetDelay={message.delay}
          index={index}
          currentAudioWithStatus = {currentAudioWithStatus}
          setCurrentAudioWithStatus = {setCurrentAudioWithStatus}
          renderDebugPopover={renderDebugPopover}
          renderFeedbackIcon={renderFeedbackIcon}
          openDocument={openDocument}
          sendMessage={sendMessage}
          openImage={openImage}
          startChat={startChat}
          renderMap={renderMap}
          renderPdf={renderPdf}
          getSelfHealingCommand={getSelfHealingCommand}
          config={config}
          default_language={default_language}
          getTerm={getTerm}
          getPatchedMessageContent={getPatchedMessageContent}
          autoScroll={autoScroll}
        />
      );
    });
  };

  const renderOptionStyle = (option) => {
    if (option.styleInfo === 'red'){
      return 'danger';
    }
    if (option.styleInfo === 'back'){
      return 'default';
    }

    return 'primary';
  };

  const filterOptions = (options) => {

    if (options) {
      return options.filter(i => {
        return userInput &&
          (
            (allowOptionFiltering && interaction.inputType === 'vertical_select') ||
            (interaction.inputType === 'text')
          ) ?
          i.label.toLowerCase().indexOf(userInput.toLowerCase().trim()) !== -1 :
          i;
      });
    }

    return [];
  }

  const renderOptionLabel = (item) => {

    let label = item.label;

    if (userInput && ((allowOptionFiltering && interaction.inputType === 'vertical_select') ||
      interaction.inputType === 'text')) {

      let index = label.toLowerCase().indexOf(userInput.toLowerCase().trim());

      if (index !== -1) {

        let length = userInput.toLowerCase().trim().length;

        let prefix = label.substring(0, index);
        let suffix = label.substring(index + length);
        let match = label.substring(index, index + length);

        return (
          <span>
            {prefix}<span className="searchString" >{match}</span>{suffix}
          </span>
        );
      }
    }

    return (
        <span>
            {label}
        </span>
    );
  };

  const getOptions = () => {
    let options = [];
    options = interaction.inputOptions;
    if (allowOptionFiltering || interaction.inputType === 'text') {
      options = filterOptions(interaction.inputOptions);
    }

    if (options && options.some(o => o.metadata && o.metadata.expr)) {
      return [];
    }

    return options;
  };

  const options = getOptions() || [];
  const optionsEnabled = (options.length > 0 && inputType !== 'text') ||
    (inputType === 'text' && userInput && options.length > 0);

  const renderOptions = () => {
    if (chatStarted) {
      return;
    }

    if (optionsEnabled) {
      return (
        <div className="options-container" ref={optionsHolder}>
          <List
            size="small"
            dataSource={options}
            position="both"
            pagination={
              {
                total: options.length,
                pageSize: showResultCount,
                defaultPageSize: showResultCount,
                simple: true,
                hideOnSinglePage: true,
                current: optionsPage,
                itemRender: (page, type) => {
                  if (type === 'next') {
                    return <Button type="primary" block ghost><RightOutlined /></Button>
                  }
                  if (type === 'page') {
                    return <Button type="primary" block >{page}</Button>
                  }
                  if (type === 'prev') {
                    return <Button type="primary" block ghost ><LeftOutlined /></Button>
                  }
                },
              // defaultCurrent: 1,
                position: "both",
                onChange: setOptionsPage
              }
            }
            renderItem={item => (
              <Button
                onClick={() => onOptionClick(item)}
                block
                type={renderOptionStyle(item)}
              >
                <Space>
                  {renderOptionLabel(item)}
                  {renderFeedbackIcon(item, true)}
                </Space>
              </Button>
            )}
          />
        </div>
      );
    }
    return null;
  }

  const menu = (
    <Menu onClick={onLanguageChange} selectedKeys={[default_language]}>
      {
        _supportedLanguages.map((lang) => (
          <Menu.Item key={lang.toLowerCase()}>
            <Button type="text">{LANGUAGE_LIST[lang.toLowerCase()].nativeName}</Button>
          </Menu.Item>
        ))
      }
    </Menu>
  );

  return (
    <div style={{height:`${fullHeight}px`}} className={inIframe() ? 'kbot-container chatbot-in-iframe' : 'kbot-container chatbot-notin-iframe'} >
      <Card id="kbot-card" title={renderTitle()} className={debug_mode ? 'kbot-card debug-mode' : 'kbot-card'} bordered={true}>
        {
          error && (
            <div className="error-holder">{error}</div>
          )
        }
        {
          pdf && pdf.title && (
            <div class="pdf-container">
              <iframe title="pdf" src={pdf.url} frameborder="0">
              </iframe>
            </div>
          )
        }
        {
          docLink && viewContent && (!pdf) && (
            <div className="messages-container">
              <div className="content bot-doc-viewer" id="bot-doc-viewer">
                <div dangerouslySetInnerHTML={{ __html: viewContent }}></div>
              </div>
            </div>
          )
        }
         {
           !docLink && (!(pdf && pdf.title)) && (
            <div className="messages-wrapper">
              <div
                className={optionsEnabled && !chatStarted ? 'messages-container with-options' : 'messages-container'}
                ref={messagesEnd}
              >
                {
                  showInEffect && (
                    <SequentialList>
                    {renderMessages()}
                    </SequentialList>
                  )
                }
                <div id="hidden-holder"></div>
                {
                  loading && (
                    <Space align="end">
                      {
                        config && config.showAvatar && (
                          <Avatar src={`${config.baseURL}kbot-widget/bots${config.avatarSource.replace('.', '')}`} />
                        )
                      }
                      <div className="message-holder">
                        <div className={`message bot`} >
                        <img src="images/loading-dots.gif" className="loading-img" alt="loading" />
                        </div>
                      </div>
                    </Space>
                  )
                }
                {
                  executing && (
                    <Space align="end">
                      {
                        config && config.showAvatar && (
                          <Avatar src={`${config.baseURL}kbot-widget/bots${config.avatarSource.replace('.', '')}`} />
                        )
                      }
                      <div className="message-holder">
                        <div className={`message bot`} >
                          <SettingOutlined spin />
                        </div>
                      </div>
                    </Space>
                  )
                }
              </div>
              {renderOptions()}
              {
                inputFieldVisible && (
                  <div className="input-container">
                    <div className="input-holder">
                      {
                        agentTyping && (
                          <span class="agent-typing">{getTerm('AGENT_TYPING', default_language)}</span>
                        )
                      }
                      <Form
                        name="basic"
                        form={QueryForm}
                        initialValues={{ query: '' }}
                        onFinish={sendMessage}
                      >
                        <Row gutter="16">
                          <Col span={interaction.inputType === 'binary' ? 12 : 20} onClick={() => setDebugModeCounter(DebugModeCounter + 1)}>
                            <Form.Item
                              name="query"
                            >
                              <Input
                                onKeyUp={onUserInput}
                                onChange={onChange}
                                autoFocus
                                onPressEnter={onPressEnter}
                                disabled={!inputFieldActive}
                                size="large"
                                ref={QueryInput}
                                bordered={false}
                                placeholder={inputHint}
                                tabIndex={1}
                              />

                            </Form.Item>
                          </Col>
                          {interaction.inputType === 'binary' && <Col span={6}>
                            <Tooltip
                              placement="top"
                              title={file && file.name ? 'Delete the file' : 'Upload a file'}
                            >
                              <Button
                                size="large"
                                className='upload-file-btn'
                                type="default"
                                onClick={onClickFileButton}
                                icon={file && file.name ? <DeleteOutlined />: <PaperClipOutlined />}
                                > {file && file.name ? truncate(file.name) : ''}
                                </Button>
                            </Tooltip>
                          </Col>}

                          <Col span={interaction.inputType === 'binary' ? 6 : 4}>
                            <Button
                              disabled={!inputFieldActive}
                              size="large"
                              type="primary"
                              onClick={(allowOptionFiltering && interaction.inputType === 'vertical_select') || (interaction.inputType === 'text') ? onPressEnter : submitForm}
                              block
                              tabIndex={2}
                            >
                              <SendOutlined />
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
      </Card>
      <AddUploadModal
         uploadLimit={config.uploadLimit}
         isUploadModalVisible={isUploadModalVisible}
         setIsUploadModalVisible={setIsUploadModalVisible}
         file={file}
         setFile={setFile}
      />
      <AddFeedbackModal
        SelectedMessage={SelectedMessage}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        default_language={default_language}
        apiKey={config.key}
      />
      <Modal
        className="bot-lightbox-modal"
        footer={null}
        header={null}
        width={'90%'}
        visible={showLightBox}
        onCancel={() => setShowLightBox(false)}
      >
        <img src={lightboxImage} alt="" />
      </Modal>
    </div>

  );
}

export default Bot;
