import React, { useEffect, useState } from 'react';
import { message, Button } from 'antd';
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons';

message.config({
  getContainer: () => document.getElementById('kbot-card')
});

const AudioPlayer = (props) => {
  const [audio, setAudio] = useState(false);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (props.text) {
      setAudio(new Audio(`${props.config.baseURL}kbot-api/interact/${props.config.key}/widget:Default/convertAndGetSpeech?text=${props.text}`));
    }
  }, [props.text])


  useEffect(() => {
    if (audio) {
      audio.onended = function () {
        setPlaying(false);
        props.setCurrentAudioWithStatus({ audio: false, playing: false });
      }
    }

  }, [audio]);


  const onClick = () => {
    if (playing || props.currentAudioWithStatus.audio) {
      audio.pause();
      setPlaying(false);
      props.currentAudioWithStatus.audio.pause();
      props.setCurrentAudioWithStatus({ audio: false, playing: false });
    } else {
      if (props.currentAudioWithStatus.audio) {
        props.currentAudioWithStatus.audio.pause();
      }
      audio.play();
      setPlaying(true);
      props.setCurrentAudioWithStatus({ audio: audio, playing: true });
    }
  }

  if (props && props.config && props.config.textToSpeechEnabled && audio) {
    return (
      <>
        <span class="audio-icon">
          {(playing || (props.currentAudioWithStatus && props.currentAudioWithStatus.audio && props.currentAudioWithStatus.audio.src === audio.src)) && (<span><PauseCircleOutlined onClick={onClick} /></span>)}
          {!(playing || (props.currentAudioWithStatus && props.currentAudioWithStatus.audio && props.currentAudioWithStatus.audio.src === audio.src))  && (<span><PlayCircleOutlined onClick={onClick} /></span>)}
        </span>
      </>
    )
  }
  return null;

};

export default AudioPlayer;