import { generateUUID, urlParam,  getSpeechTextForMessage } from '../config/utils';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  config: false,
  default_interaction: {},
  topic:false,
  bubbles: [],
  interaction:[],
  languages: [],
  default_language: false,
  debug_mode: false,
  session_id: false,
  request_counter: 0,
  vars: Object.fromEntries(new URLSearchParams(window.location.search)),
  user_id: false,
  debug_data: false,
  scroll_id: false
}

export const botSlice = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    startSession: (state, action) => {
      const debug_data = action.payload.response.debug;
      const payload = action.payload.response.payload[0];
      return Object.assign({}, state, {
        topic: payload.topic,
        audios: state.config.textToSpeechEnabled && state.config.autoPlay && [...payload.conversation.bubbles.filter((message, index) => getSpeechTextForMessage(message.content)).map((message, index) => {
          const speech = getSpeechTextForMessage(message.content);
          const audio = speech && new Audio(`${state.config.baseURL}kbot-api/interact/${state.config.key}/widget:Default/convertAndGetSpeech?text=${speech}`);
          return {audio: audio, index: index, playing: false}
        })],
        bubbles: [
          ...state.bubbles,
          ...payload.conversation.bubbles.map(
            (bubble, index) => {
              bubble.type = 'bot';
              bubble.debug_mode = state.debug_mode;
              bubble.id = index;
              return bubble;
            }
          )
        ],
        session_id: action.payload.sessionAttributes.sessionId,
        interaction: payload.interaction,
        default_interaction: payload.interaction,
        default_language: payload.language,
        request_counter: state.request_counter + 1,
        user_id: generateUUID(),
        debug_data: debug_data
      });
    },
    setConfig: (state, action) => {
      return Object.assign({}, state, {
        config: {
          ...state.config,
          ...action.payload,
          key: action.payload.agent.key,
          _supportedLanguages: action.payload.agent.languageList,
          name: action.payload.agent.name,
          languageDetectionMethod: action.payload.uiStyle['language-detection-method'],
          languageQueryParameter: action.payload.uiStyle['language-query-parameter'],
          fallbackLanguage: action.payload.uiStyle['fallback-language']
        }
      });
    },
    setConfigNormal: (state, action) => {
      return Object.assign({}, state, {
        config: {
          ...state.config,
          ...action.payload
        }
      });
    },
    runCommand: (state, action) => {
      return Object.assign({}, state, {
        bubbles: [...state.bubbles],
        request_counter: state.request_counter + 1,
        user_id: generateUUID()
      });
    },
    sendServerMsg: (state, action) => {
      const debug_data = action.payload.response.debug;
      let new_interaction = Object.assign(state.default_interaction, {});
      const payload = action.payload.response.payload && action.payload.response.payload[0] || false;
      if (!payload) {
        return state;
      }
      if (payload.interaction && payload.interaction.inputHint) {
        new_interaction = {...state.interaction, ...payload.interaction};
      }

      if (payload.interaction && !payload.interaction.inputOptions) {
        new_interaction = { ...new_interaction, inputOptions: [] };
      }

      const lastBubble = [...state.bubbles][state.bubbles.length - 1];
      let bubbles = (payload.conversation && payload.conversation.bubbles) || [];
      bubbles = bubbles.map((bubble, index) => ({
        ...bubble,
        id: state.bubbles.length + index + 1
      }))
      let audioBubbles = [...bubbles];
      if (lastBubble && lastBubble.type === 'user') {
        audioBubbles = [lastBubble, ...bubbles];
      }

      return Object.assign({}, state, {
        topic: payload.topic,
        audios:  state.config.textToSpeechEnabled && state.config.autoPlay && [...audioBubbles.filter((message, index) => getSpeechTextForMessage(message.content)).map((message, index) => {
          const speech = getSpeechTextForMessage(message.content);

          const audio = speech && new Audio(`${state.config.baseURL}kbot-api/interact/${state.config.key}/widget:Default/convertAndGetSpeech?text=${speech}`);
          return {audio: audio, index: index, playing: false}
        })],
        bubbles: [
          ...state.bubbles,

          ...bubbles.map(
            item => {
              item.type ='bot';
              if (state.debug_mode) {
                item.debug = action.payload.response.debug && action.payload.response.debug.state;
                item.debug_mode = state.debug_mode;
              }
              return item;
            }
          )
        ],
        interaction: new_interaction,
        request_counter: state.request_counter + 1,
        debug_data: debug_data
      });
    },
    sendAgentMsg: (state, action) => {
      return Object.assign({}, state, {
        bubbles: [...state.bubbles, action.payload],
      });
    },
    closeLiveChat: (state, action) => {
      return Object.assign({}, state, {
        bubbles: [...state.bubbles, {content: '', type: 'closeChat'}],
      });
    },
    startLiveChat: (state, action) => {
      return Object.assign({}, state, {
        bubbles: [...state.bubbles, {content: '', type: 'startChat'}],
        interaction: { ...state.default_interaction, inputHint: action.payload},
        request_counter: state.request_counter + 1
      });
    },
    sendUserMsg: (state, action) => {
      return Object.assign({}, state, {
        bubbles: [...state.bubbles, {
          ...action.payload,
          id: state.bubbles.length + 1
        }],
        scroll_id: state.bubbles && state.bubbles.length > 0 ? state.bubbles[state.bubbles.length-1].id : 0
      });
    },
    changeLanguage: (state, action) => {
      return Object.assign({}, state, {
        default_language: action.payload,
      });
    },
    resetSession: (state, action) => {
      const debug_data = action.payload.response.debug;
      const payload = action.payload.response.payload[0];
      return Object.assign({}, state, {
        topic: payload.topic,
        audios: state.config.textToSpeechEnabled && state.config.autoPlay && [...payload.conversation.bubbles.filter((message, index) => getSpeechTextForMessage(message.content)).map((message, index) => {
          const speech = getSpeechTextForMessage(message.content);
          const audio = speech && new Audio(`${state.config.baseURL}kbot-api/interact/${state.config.key}/widget:Default/convertAndGetSpeech?text=${speech}`);
          return {audio: audio, index: index, playing: false}
        })],
        bubbles: [
          ...payload.conversation.bubbles.map(
            bubble => {
              bubble.type = 'bot'; bubble.debug_mode = state.debug_mode;
              return bubble;
            }
          )
        ],
        session_id: action.payload.sessionAttributes.sessionId,
        interaction: payload.interaction,
        default_language: payload.language,
        request_counter: 1,
        debug_data: debug_data
      });
    },
    toggleDebug: (state, action) => {
      return Object.assign({}, state, { debug_mode: !state.debug_mode });
    },
    startDebug: (state, action) => {
      return Object.assign({}, state, { debug_mode: urlParam('enableDebug') === 'true' });
    },
    loadDocument: (state, action) => {
      return state;
    },
    sendFeedback: (state, action) => {
      return state;
    },
    convertTextToSpeech: (state, action) => {
      return state;
    },
    executeNativeMessage: (state, action) => {
      return Object.assign({}, state, {
        lastNativeMessagingResult: { result: action.payload.result.Value },
      });
    },

  },
})
const BotReducer = {
  actions: botSlice.actions,
  reducer: botSlice.reducer
}

export default BotReducer;
