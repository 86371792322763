const Chat = (config) => {
  let {
    BotReducer: {
      config: {
        chat,
        chatType,
        chatUrl,
        lang,
        key,
      },
      session_id
    },
    sendAgentMessage,
    sendServerMessage,
    BotReducer,
    getTerm
 } = config;

  let connection;
  let chatDomain;
  let RocketChatProtocolSent = false;
  let RocketChatSubscribed = false;
  let closeCallback = null;
  let messageID = false;
  let sendMessageRequest_LiveChat = (msg) => sendServerMessage(msg, BotReducer, 'live-chat-agent');
  const addAgentMessage = (msg) => sendAgentMessage({content: msg, type: 'agent'});

  if (chat !== '') {
    chat = JSON.parse(chat);
    if(Array.isArray(chat)) {
      chat.forEach(item => {
        if(item.key === 'url') {
          chatUrl = item.value;
        }
      });
    } else {
      chatUrl = chat.url || '';
    }
  }

  if (chatType === 'RocketChat') {
    let url = new URL(chatUrl.replace(/\\/g, ''));
    const protocol = url.protocol.indexOf('https') !== -1 ? 'wss' : 'ws';
    chatUrl = protocol + '://' + url.host + '/websocket';
    chatDomain = url.protocol + '//' + url.host;
  }

  const RocketChatStart = () => {
    // connect to rocket chat
    connection.send(JSON.stringify({
      "msg": "connect",
      "version": "1",
      "support": ["1","pre2","pre1"]
    }));
  };

  const RocketChatRegisterGuest = () => {

    // register guest
    let guest = window.localStorage.getItem('ChatGuest');
    if (guest) {
      guest = JSON.parse(guest);
      RocketChatSetupConnection(guest.token);
      RocketChatGetInitialData(guest.token);
      return ;
    }

    fetch( `${chatDomain}/api/v1/livechat/visitor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "visitor": {
          "token": window.localStorage.getItem('ChatToken')
        }
      }),
    })
    .then(response => response.json())
    .then(data => {
      window.localStorage.setItem('ChatGuest', JSON.stringify(data.visitor));
      RocketChatSetupConnection(data.visitor.token);
      RocketChatGetInitialData(data.visitor.token);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const RocketChatGetInitialData = (token) => {
    addAgentMessage(getTerm('AGENT_PENDING', lang));
    fetch(`${chatDomain}/api/v1/livechat/config?token=${token}`)
    .then(response => response.json())
    .then(data => {
      window.localStorage.setItem('ChatData', JSON.stringify(data.config));
      if (data.config.room) {
        window.localStorage.setItem('ChatRoomID', data.config.room._id);
      }
      if (data.config.online) {
        addAgentMessage(getTerm('AGENT_CONNECTED', lang));
        // addAgentMessage('You are connected to agent: ' + data.config.agent.name);
      } else {
        addAgentMessage(getTerm('AGENT_NOTAVAILABLE', lang));
      }
      RocketChatGetRoomData(token);
    });
  };

  const RocketChatGetRoomData = (token, cb) => {
    let room = window.localStorage.getItem('ChatRoomID');
    if (room) {
      RocketChatSubscribeToChat(room, token);
      cb && cb(room);
      return;
    }
    fetch(chatDomain + '/api/v1/livechat/room?token=' + token)
    .then(response => response.json())
    .then(data => {
      if (data && data.room) {
        room = data.room._id;
        window.localStorage.setItem('ChatRoomID', room);
        RocketChatSubscribeToChat(room, token);
        cb && cb(room);
      }
    });
  };

  const RocketChatSetupConnection = (token) => {
    connection.send(JSON.stringify({
      "msg": "method",
      "method": "livechat:setUpConnection",
      "params":[{"token": token}],
      "id": "ddp-" + Date.now().toString()
    }));
  };

  const RocketChatGetProtocol = (room, token) => {
    fetch(`/kbot-api/protocol/${encodeURIComponent(key)}/json?sessionid=${session_id}`)
    .then(response => response.json())
    .then(data => {
      const sep = '--------------------------------------------------------';
      const end = '==========================================';
      const message = data.messages.filter(item => item.type === 'message')
        .map(item => sep + "\n(" + item.who + "): " + item.content[0]);
        connection.send(JSON.stringify({
          "msg":"method",
          "method":"sendMessageLivechat",
          "params":[
            {
              "_id": Date.now().toString(),
              "rid": room,
              "msg": "** Bot Chat Protocol - Bot(" + encodeURIComponent(key) + ") - Language (" + lang + ") **\n" + message.join("\n") + "\n" + end + "\n",
              "token": token
            }
          ],
          "id": Date.now().toString()
        }));
    });
  };

  const RocketChatSubscribeToChat = (room, token) => {
    if (!RocketChatSubscribed){
      connection.send(JSON.stringify({
        "msg":"sub",
        "name":"stream-room-messages",
        "params":[
          room,
          {
            "useCollection":false,
            "args":[
              {
                "token": token,
                "visitorToken": token
              }
            ]
          }
        ],
        "id": Date.now().toString()
      }));
      RocketChatSubscribed = true;
    }
    if (!RocketChatProtocolSent) {
      RocketChatGetProtocol(room, token);
      RocketChatProtocolSent = true;
    }
  };

  const RocketChatOnMessage = (evt, cb) => {
    const message = JSON.parse(evt.data)
    if (message.msg === 'connected') {
      RocketChatRegisterGuest();
    }
    if (message.msg === 'ping') {
      connection.send(JSON.stringify({
        "msg": "pong"
      }));
    }
    if (message.msg === 'changed' && message.collection === 'stream-room-messages') {
      const guest = JSON.parse(window.localStorage.getItem('ChatGuest'));
      const msg = message.fields.args[0].msg || false;
      const type = message.fields.args[0].t || false;
      if (msg === 'connected' && type === 'command') {
        return;
      }
      if (!msg) {
        messageID = msg;
        window.localStorage.removeItem('ChatRoomID');
        addAgentMessage(getTerm('AGENT_CLOSED', lang));
        closeCallback && closeCallback(getTerm('AGENT_CLOSED', lang));
        return;
      }


      if ((msg && msg === 'promptTranscript' && msg !== messageID)) {
        window.localStorage.removeItem('ChatRoomID');
        messageID = msg;
        addAgentMessage(getTerm('AGENT_CLOSED', lang));
        closeCallback && closeCallback(getTerm('AGENT_CLOSED', lang));
      } else if (message.fields.args[0].u._id !== guest._id && message.fields.args[0]._id !== messageID && msg !== 'promptTranscript') {
        addAgentMessage(msg);
        sendMessageRequest_LiveChat(msg);
        messageID = message.fields.args[0]._id;
      }
    }
    cb && cb();
  }

  const RocketChatSendFn = (evt) => {};

  const RocketChatSendMessage = (value) => {
    const now = Date.now().toString();
    const guest = JSON.parse(window.localStorage.getItem('ChatGuest'));
    RocketChatGetRoomData(guest.token, function(room) {
      connection.send(JSON.stringify({
        "msg":"method",
        "method":"sendMessageLivechat",
        "params":[
          {
            "_id": now,
            "rid": room,
            "msg": value,
            "token": guest.token
          }
        ],
        "id": now
      }));
    });

  };

  const init = (initialLang, initSessionID, cb) => {
    lang = initialLang;
    session_id = initSessionID;
    closeCallback = cb;
    if (chatType === 'RocketChat'){
      if(!connection) {
        console.log("Try to start chat session with chat Url: " + chatUrl);
        const guest = window.localStorage.getItem('ChatToken');
        if (!guest) {
          window.localStorage.setItem('ChatToken',
            (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase() + Date.now().toString()
          );
        }
        connection = new WebSocket(chatUrl);
        connection.onopen = RocketChatStart;
        connection.onmessage = RocketChatOnMessage;
        connection.sendFn = RocketChatSendFn;
      }
    }
  };

  const close = (userClsoe, cb) => {
    // chatSessionId = null;
    if (userClsoe) {
      addAgentMessage(getTerm('AGENT_USERCLOSED', lang));
      if (chatType === 'RocketChat') {
        RocketChatSendMessage('Chat was closed by the user');
      }
    }
    window.localStorage.removeItem('ChatRoomID');
    cb && cb();
    connection.close();
    RocketChatSubscribed = false;
    connection = false;
  };

  return {
    connection,
    RocketChatSendMessage,
    RocketChatSendFn,
    RocketChatOnMessage,
    RocketChatSubscribeToChat,
    RocketChatGetProtocol,
    RocketChatSetupConnection,
    RocketChatGetRoomData,
    RocketChatGetInitialData,
    RocketChatRegisterGuest,
    RocketChatStart,
    init,
    close
  };
};

export default Chat;
