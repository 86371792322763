import React, { useEffect, useRef } from 'react';
import { Button } from "antd";
import { ArrowRightOutlined, FileOutlined } from '@ant-design/icons';
import MessageWrapper from "./message-wrapper";
import { parse } from "node-html-parser";
import AudioPlayer from "./audio";
import { getSpeechTextForMessage } from '../../config/utils';

const Message = (props) => {
  const {
    message,
    openDocument,
    openImage,
    startChat,
    renderMap,
    renderPdf,
    index,
    getSelfHealingCommand,
    config,
    default_language,
    getTerm,
    getPatchedMessageContent,
    currentAudioWithStatus,
    setCurrentAudioWithStatus,
    sendMessage,
    autoScroll
   } = props;

  const messageRef =  useRef(null);

  useEffect(() => {
    autoScroll();
  }, []);

  const patchedContent = getPatchedMessageContent(message.content, message.type);
  const root = parse(patchedContent);

  const renderElement = (element, children) => {
    if (element._rawText) {
      if (element._rawText.indexOf('[map:') !== -1) {
        const matches = Array.from(element._rawText.matchAll(/\[([^\][]*)]/g), x => x[1]);
        const parts = element._rawText.split(/\[([^\][]*)]/g);
        return parts.map(item => {
          if (matches.indexOf(item) !== -1) {
            const position = item.split(',').map(i => parseFloat(i));
            return renderMap(message, index, position);
          }

          return <span dangerouslySetInnerHTML={{ __html: item }}></span>;
        })
      }

      return <span dangerouslySetInnerHTML={{ __html: element._rawText }}></span>;
    }
    if (element.rawTagName && element.rawTagName === 'a') {
      if (element.attrs && element.attrs['data-kbot-action']) {
        const action = element.attrs['data-kbot-action'];
        if (action === 'select') {
          const value = element.attrs['data-kbot-value'];
          return (
            <Button type="primary" block onClick={() => sendMessage({ query: value })} >
              {element.innerHTML}
            </Button>
          );
        }
        return null;
      }

      if (element.attrs && element.attrs['data-kbot-uploaded-file']) {

        const guid = element.attrs['data-kbot-uploaded-file'];
        return (
          <a target="_blank" rel="noreferrer" href={guid}>
            <Button type="primary" disabled ghost block>
            <FileOutlined />
              <span dangerouslySetInnerHTML={{ __html: element.innerHTML }}></span>
            </Button>
          </a>
        )
      }

      if (element.attrs && element.attrs['data-kbot-link']) {
        const guid = element.attrs['data-kbot-link'];
        return (
          <a target="_blank" rel="noreferrer" href={guid}>
            <Button type="primary" ghost>
              <span dangerouslySetInnerHTML={{ __html: element.innerHTML }}></span> <ArrowRightOutlined />
            </Button>
          </a>
        )
      }

      if (element.attrs && element.attrs['data-kbot-pdf']) {
        const guid = element.attrs['data-kbot-pdf'];
        return (
          <Button type="primary" onClick={() => renderPdf(element.innerText, guid)} ghost>
            <span dangerouslySetInnerHTML={{ __html: element.innerHTML }}></span> <ArrowRightOutlined />
          </Button>
        )
      }

      if (element.attrs && element.attrs['data-kbot-agent']) {
        return (
          <Button type="primary" onClick={() => startChat()} ghost block>
            OpenChat
          </Button>
        )
      }

      if (element.attrs && element.attrs['data-kbot-doc']) {
        const guid = element.attrs['data-kbot-doc'];
        let externalAgentKey;
        if (element.attrs['data-kbot-external-key']) {
          externalAgentKey = element.attrs['data-kbot-external-key'];
        }
        return (
          <Button type="primary" onClick={() => openDocument(guid, externalAgentKey ? externalAgentKey : '')} ghost>
            <span dangerouslySetInnerHTML={{ __html: element.innerHTML }}></span> <ArrowRightOutlined />
          </Button>
        );
      }
    }

    if (element.rawTagName && element.rawTagName === 'img') {
      const src = element.attrs && element.attrs.src;
      return (
        <Button type="link" onClick={() => openImage(src)}>
          <img onLoad={() => autoScroll() }  src={src} alt="" />
        </Button>
      );
    }

    if (element.rawTagName && element.rawTagName === 'audio') {

      return getSpeechTextForMessage(message.content) && <AudioPlayer
        currentAudioWithStatus = {currentAudioWithStatus}
        setCurrentAudioWithStatus = {setCurrentAudioWithStatus}
        message={message}
        index={index}
        config={config}
        text={getSpeechTextForMessage(message.content)}
        url={`${config.baseURL}kbot-api/interact/${config.key}/widget:Default/convertTextToSpeech`}
      />
    }

    if (element.rawTagName && element.rawTagName === 'div') {
      if (element.attrs && element.attrs['data-view-type']) {
        const view = element.attrs['data-view-type'];
        if (view === 'map') {
          const lat = element.attrs['data-latitude'];
          const lon = element.attrs['data-longitude'];
          const position = [parseFloat(lat), parseFloat(lon)];
          return renderMap(message, index, position);
        }
      }
    }


    return  <span dangerouslySetInnerHTML={{ __html: element.outerHTML }}></span>;
  };


  const renderMessageParts = (list) => {
    const elements = list || root.childNodes;
    return elements.map((node) => {
      if (node && node.childNodes && node.childNodes.length > 0) {
        const nested = node.childNodes.filter((i) => i._rawText);

        if (nested.length === 1) {
          return renderElement(node);
        } else {
          return renderMessageParts(node.childNodes);
        }
      }
      return renderElement(node);
    });
  };

  const shCommand = getSelfHealingCommand(message);
  if (shCommand) {
    return (
      <MessageWrapper {...props} >
        <span dangerouslySetInnerHTML={{ __html: getTerm('ACTION_PERFORMED', default_language) }}></span>
      </MessageWrapper>
    );
  }

  return (
    <div ref={messageRef} id={message.id} >
      <MessageWrapper {...props} >
        {renderMessageParts()}
      </MessageWrapper>
    </div>
  );
}

export default Message;
