export const generateUUID = ()=> {
  var d = new Date().getTime();
  var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;
      if(d > 0){
          var r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {
          var r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c=='x' ? r : (r&0x7|0x8)).toString(16);
  });
  return uuid;
}

export const getSpeechTextForMessage = (content) => {
  const htmlTags = /(<\/[^>]*>)|<[^>]+>/ig;
  const specialPartsRemoved = content.replaceAll(htmlTags, '').replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');

  return specialPartsRemoved && encodeURIComponent(specialPartsRemoved);

}

export const urlParam = (name) => {
  var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.search);
  return (results !== null) ? results[1] || 0 : false;
};

export const LANGUAGE_LIST = {
  cs: { name: 'Czech', nativeName: 'čeština' },
  de: { name: 'German', nativeName: 'Deutsch' },
  en: { name: 'English', nativeName: 'English' },
  es: { name: 'Spanish', nativeName: 'Español' },
  fr: { name: 'French', nativeName: 'Français' },
  it: { name: 'Italian', nativeName: 'Italiano' },
  pt: { name: 'Portuguese', nativeName: 'Português' },
  ru: { name: 'Russian', nativeName: 'Русский' },
  lt: { name: 'Lithuanian', nativeName: 'Lietuvių Kalba' }
}