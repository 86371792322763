const Languages = {
  de: {
    'input-message-error': 'Deutsch Please fill out your message',
    'debug-info': 'Debug-Informationen',
    'state': 'Zustand',
    'header.lang.chooser.title': 'Sprache ändern',
    'header.reset.button.title': 'Bot neu starten',
    'header.news.button.title': 'Bot Benachrichtigungen',
    'header.close.button.title': 'Zurück',
    'header.chat.button.title': 'Zum Chat wechseln',
    'header.chat.close.button.title': 'Chat beenden',
    'header.help.title': 'Hilfe anzeigen'
  },
  en: {
    'input-message-error': 'Please fill out your message',
    'debug-info': 'Debug Info',
    'state': 'State',
    'header.lang.chooser.title': 'Choose a language',
    'header.reset.button.title': 'Restart Bot',
    'header.news.button.title': 'Bot Notifications',
    'header.close.button.title': 'Go Back',
    'header.chat.button.title': 'Switch to the chat',
    'header.chat.close.button.title': 'Close Chat',
    'header.help.title': 'Show help'
  },
  lt: {
    'input-message-error': 'Please fill out your message',
    'debug-info': 'Debug Info',
    'state': 'State',
    'header.lang.chooser.title': 'Pasirinkti kalbą',
    'header.reset.button.title': 'Paleisti iš naujo',
    'header.news.button.title': 'Pranešimai',
    'header.close.button.title': 'Atgal',
    'header.chat.button.title': 'Perjungti į pokalbį',
    'header.chat.close.button.title': 'Išjungti pokalbį',
    'header.help.title': 'Rodyti pagalbą'
  },
  fr: {
    'input-message-error': 'Veuillez taper votre message',
    'debug-info': 'Debug Info',
    'state': 'Etat',
    'header.lang.chooser.title': ' Choisissez une langue',
    'header.reset.button.title': 'Relancer le robot',
    'header.news.button.title': 'Notifications du bot',
    'header.close.button.title': 'Retourner',
    'header.chat.button.title': 'Passer au chat',
    'header.chat.close.button.title': 'Fermer le chat',
    'header.help.title': 'Afficher assistance'
  },
  getTerm: (term, lang) => {
    return (Languages[lang] && Languages[lang][term]) || Languages['en'][term];
  }
};

export default Languages;