import { generateUUID } from '../../config/utils';

const Chat = (config) => {
  let {
    BotReducer: {
      config: {
        chat,
        chatType,
        chatSystemId,
        chatTenantId,
        chatChannelId,
        chatUrl,
        lang,
        key,
      },
      session_id
    },
    sendAgentMessage,
    sendServerMessage,
    BotReducer,
    getTerm
 } = config;

  let connection;
  let closeCallback = null;
  let chatInactive;
  let chatSessionId = null;
  let sendMessageRequest_LiveChat = (msg) => sendServerMessage(msg, BotReducer, 'live-chat-agent');
  const addAgentMessage = (msg) => sendAgentMessage({content: msg, type: 'agent'});

  const CHATMESSAGE_INIT_ID = "client/init";
  const CHATMESSAGE_ALIVE_ID = "client/alive";
  const CHATMESSAGE_LINE_ID = "client/line";
  const CHATMESSAGE_QUIT_ID = "client/quit";

  if (chat !== '') {
    chat = JSON.parse(chat);
    if(Array.isArray(chat)) {
      chat.forEach(item => {
        if(item.key === 'url') {
          chatUrl = item.value;
        }
        if(item.key === 'system_id') {
          chatSystemId = item.value;
        }
        if(item.key === 'tenant_id') {
          chatTenantId = item.value;
        }
        if(item.key === 'channel_id') {
          chatChannelId = item.value;
        }
      });
    }
  }

  const sendChatInitMessage = () => {
    chatSessionId = generateUUID();
    var initPayload =
    {
      "id": CHATMESSAGE_INIT_ID,
      "chat-session-id": chatSessionId,
      "pre-surveydata": {},
      "tenant-id": chatTenantId,
      "channel-id": chatChannelId,
      "system-id": chatSystemId,
      "find-agent": true,
      "external-sid": session_id,
      "external-agent": encodeURIComponent(key),
      "external-protocol-path": '/kbot-api/protocol/' + encodeURIComponent(key) + '/json?sessionid=' + session_id,
      "language": lang,
      "type": "bot",
      "group-id": undefined,
      "reconnectable": false,
      "customerclientmetadata": [{"key": "starturl", "value": window.location.href}]
    }

    connection.sendFn(CHATMESSAGE_INIT_ID, initPayload);

    addAgentMessage(getTerm('AGENT_PENDING', lang));
  };

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  const chatCallbackFunction = (param) => {
    if (param) {
      if (param.id === 'handshake') {
        sendChatInitMessage();
      } else if(param.id === 'recv') {
        if (param.data && param.data[1] && param.data[1]['chat-session-id'] === chatSessionId) {
            if(param.data[0] === 'no-assistance') {
              addAgentMessage(getTerm('AGENT_NOTAVAILABLE', lang));
            } else if(param.data[0] === 'timeout-warning') {
              chatInactive = true;
            } else if(param.data[0] === 'joined-session') {
              addAgentMessage(getTerm('AGENT_CONNECTED', lang) );
            } else if(param.data[0] === 'line') {
              if (param.data[1] && param.data[1].line) {
                addAgentMessage(decodeHtml(param.data[1].line.replace(/(&nbsp;|<([^>]+)>)/ig, '')));
                sendMessageRequest_LiveChat(param.data[1].line, 'agent ' + param.data[1].author);
              }
            } else if(param.data[0] === 'ended'){
              addAgentMessage(getTerm('AGENT_CLOSED', lang));
              closeCallback && closeCallback(getTerm('AGENT_CLOSED', lang));
            } else if(param.data[0] === 'timeouted') {
              addAgentMessage(getTerm('AGENT_TIMEOUTED', lang));
              closeCallback && closeCallback(getTerm('AGENT_TIMEOUTED', lang));
            }
          }

        }
      }
  };

  const sendChatAliveMessage = () => {
    console.log("Send chat alive message...");
    const isAlivePayload = {
      "id": CHATMESSAGE_ALIVE_ID,
      "chat-session-id": chatSessionId,
      "post-survey-result": {}
    };

    connection.sendFn(CHATMESSAGE_ALIVE_ID, isAlivePayload);
  };

  const sendChatCloseMessage = () => {
    console.log("Send chat close message...");
    debugger;
    const closePayload = {
      "id": CHATMESSAGE_QUIT_ID,
      "chat-session-id": chatSessionId,
      "post-survey-result": {},
      "type": "user"
    };
    connection.sendFn(CHATMESSAGE_QUIT_ID, closePayload);
  }

  const sendChatMessage = (p_message) => {
      if(p_message != null && p_message.trim() !== '') {
        if(chatInactive) {
          sendChatAliveMessage();
          chatInactive = false;
        }
        console.log("Send chat message...");
        const payload =
        {
          "id": CHATMESSAGE_LINE_ID,
          "chat-session-id": chatSessionId,
          "line": p_message,
          "author": "Unbekannt",
          "type": "user"
        }
        connection.sendFn(CHATMESSAGE_LINE_ID, payload);
      }
    }

  const init = (initialLang, initSessionID, cb) => {
    lang = initialLang;
    session_id = initSessionID;

    closeCallback = cb;

    if (chatType === 'sematell'){
      if(!connection) {
        if(!connection) {
          console.log("Try to start chat session with chat Url: " + chatUrl);
          connection = window.sem_chat_proto.core.connect(chatUrl, chatCallbackFunction);
        } else {
          sendChatInitMessage();
        }
      }
    }
  };

  const close = (userClsoe, cb) => {
    if (userClsoe) {
      addAgentMessage(getTerm('AGENT_USERCLOSED', lang));
      sendChatCloseMessage();
    }
    chatSessionId = null;
    cb && cb();
    connection.close && connection.close();
    connection = false;
  };

  return {
    connection,
    sendChatMessage,
    init,
    close
  };
};

export default Chat;