import React, { useEffect, useState } from 'react';
import Bot from './componants/bot';
import { connect } from 'react-redux';
import getUiStyle from './ui-style';

import {
  getConfigAction,
  setConfigAction
} from './actions/bot-actions';


const WrapperHolder = (props) => {
  const [config, setConfig] = useState(false);

  useEffect(()=> {
    if (props.config.useSettingURL){
      getConfigAction(props.config, (res) => {
        setConfig(res.data);
      });
    } else {
      setConfigAction(props.config, () => {
        setConfig(props.config);
      });
    }
  }, []);

  if (config) {
    const Wrapper = getUiStyle(config.uiStyle);
    if (config && config.enableCustomStyle) {
      return (
        <>
          <style>
            {config && config.enableCustomStyle && config.customStyle}
          </style>
          <Bot config={props.config} />
        </>
      );
    }
    return (
      <Wrapper>
        <Bot config={props.config} />
      </Wrapper>
    );
  }

  return <div />;
}

const mapStateToProps = () => ({});
export default connect(mapStateToProps, {
  getConfigAction,
  setConfigAction
})(WrapperHolder);
