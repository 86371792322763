import React, { useEffect, useState } from 'react';
import { Space, Avatar } from "antd";

const Delayed = ({
  children,
  waitBeforeShow,
  config,
  message,
  onComplete
}) => {
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
      onComplete();
    }, waitBeforeShow);
  }, [waitBeforeShow]);

  return isShown ? children : (
    <Space align="end">
      {
        config && config.showAvatar && message.type === 'bot' && (
          <Avatar src={`${config.baseURL}kbot-widget/bots${config.avatarSource.replace('.', '')}`} />
        )
      }
      <div className="message-holder">
        <div className={`message bot`} >
          <img alt="dot-loading" src="images/loading-dots.gif" className="loading-img" />
        </div>
      </div>
    </Space>
  );
};

export default Delayed;