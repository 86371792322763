const Notifications = (config) => {
  let {
    url,
    botSwPath,
  } = config;
  let _kbotUrl = url;
  let _publicSigningKey = '';
  const { screen } = window;

  const isInIframe = () => {
    try {
      if (window.frameElement)
      {
        return true;
      }
    } catch (err) { }

    if (window.self !== window.top) {
      return true;
    }
    return false;
  };

  const isSupported = () => {
    if (!('Promise' in window)) {
      console.warn('Promise isn\'t supported.');
      return false;
    }

    if (!('serviceWorker' in navigator)) {
      console.warn('ServiceWorker isn\'t supported.');
      return false;
    }

    // Check is push API is supported
    if (!('PushManager' in window)) {
      console.warn('Push messaging isn\'t supported.');
      return false;
    }

    // Check if desktop notifications are supported
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      console.warn('Notifications aren\'t supported.');
      return false;
    }

    return true;
  };

  const checkSubscription = (callback) => {
    if (!isSupported()) {
      callback(false);
      return;
    }

    navigator.serviceWorker.ready
    .then((serviceWorkerRegistration) => {
      console.log("Start register prozess!");
      serviceWorkerRegistration.pushManager.getSubscription()
      .then(function (subscription) {
        if (!subscription) {
          callback(false);
        } else {
          callback(true);
        }
      }).catch((err) => {
        // Handle the error - show a notification in the GUI
        console.warn('Error during getSubscription()', err);
        callback( false );
      });
    })
    .catch((error) => {
      console.warn('ServiceWorker not ready.', error);
      callback(false);
    });
  };

  const updateSubscription = () => {
    if (!('serviceWorker' in navigator)) {
      console.warn('ServiceWorker aren\'t supported.');
    }

    navigator.serviceWorker.ready
    .then((serviceWorkerRegistration) => {

      // Get the push notification subscription object
      serviceWorkerRegistration.pushManager.getSubscription()
      .then((subscription) => {
        if (!subscription) {
            return;
        }

        // Update the server state with the new subscription
        sendSubscriptionToServer(subscription);
      }).catch((err) => {
        // Handle the error - show a notification in the GUI
        console.warn('Error during - updateSubscription - getSubscription()', err);
      });
    }).catch(function( err ) {
      console.warn('Error during updateSubscription', err);
    });
  };

  /**
   * Step one: run a function on load (or whenever is appropriate for you)
   * Function sets up the service worker if it is supported in the
   * browser. Requires a serviceworker in a `sw.js`. This file contains what will
   * happen when we receive a push notification.
   */
  const register = () => {
    if (!('Promise' in window)) {
      console.warn('Promise is not supported in this browser.');
      return;
    }

    return new Promise((resolve, reject) => {
      if ('serviceWorker' in navigator) {
        console.log("Register subscription!");
        fetch(`${_kbotUrl}/v1/notifications/publicSigningKey`)
          .then((response) => response.arrayBuffer())
          .then((key) => { _publicSigningKey = new Uint8Array(key); })
          .then((key) => {
            navigator.serviceWorker.register(botSwPath)
              .then((req) => {
                initialiseState()
                .then((res) => resolve(res))
                .catch((err) => resolve(false));
              })
              .catch((error) => {
                console.warn('could not register serviceworker.', error);
                resolve(false);
              });
          })
          .catch((error) => {
            console.warn('Could not fetch public signing key.', error);
            resolve(false);
          });
      } else {
        console.warn('Service workers are not supported in this browser.');
        resolve(false);
      }
    });
  };

  const deregister = (callback) => {
    console.log("Deregister subscription!");
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        console.log(serviceWorkerRegistration);
        serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
          if (subscription) {
            fetch(
              `${_kbotUrl}/v1/notifications/subscription?endpoint=${encodeURIComponent(subscription.endpoint)}`,
              { method: 'DELETE' }
            ).then(function(response){
              subscription.unsubscribe().then(() => {
                callback(true);
              }).catch((e) => {
                callback(false);
              });
            }).catch((error) => {
              console.warn('Could not fetch delete subscription.', error);
              callback(false);
            });
          } else {
            callback(true);
          }
        });
      });
    }
  };

  /**
   * Step two: The serviceworker is registered (started) in the browser. Now we
   * need to check if push messages and notifications are supported in the browser
   */
  const initialiseState = () => {
    console.info('Initialise notifications.');

    const _doRegistration = () => {
      return navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {

        console.info('Initialise notifications ready().');

        // Get the push notification subscription object
        return serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
          // If this is the user's first visit we need to set up
          // a subscription to push notifications
          if (!subscription)
          {
              subscribe();
              return;
          }

          // Update the server state with the new subscription
          sendSubscriptionToServer(subscription);
        }).catch((err) => {
          // Handle the error - show a notification in the GUI
          console.warn('Error during getSubscription()', err);
        });
      }).catch((err) => {
        console.warn('Error during initialiseState ready()', err);
      });
    }

    if (!('serviceWorker' in navigator)) {
      console.warn('serviceWorker aren\'t supported.');
      return new Promise((resolve, reject) => resolve(false));
    }

    // Check is push API is supported
    if (!('PushManager' in window)) {
      console.warn('Push messaging isn\'t supported.');
      return new Promise((resolve, reject) => resolve(false));
    }

    // Check if desktop notifications are supported
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      console.warn('Notifications aren\'t supported.');
      return new Promise((resolve, reject) => resolve(false));
    }

    if (Notification.permission === 'granted') {
      console.info('Notifications allowed by user!');
      return new Promise((resolve, reject) => {
        _doRegistration()
        .then((req) => resolve(true))
        .catch((err) => reject(err));
      });
    } else {
      if (isInIframe()) {
        return new Promise((resolve, reject) => {
          const popupWinHeight = 275;
          const popupWinWidth = (screen.width <= 350) ? screen.width : 500;
          const left = (screen.width - popupWinWidth ) / 2 ;
          const top = (screen.height - popupWinHeight ) / 4 ;
          const url = window.self.location.href;
          const l_requestPermissionUrl = url.indexOf('bot.html') ? url.replace('bot.html', 'botperm.html') : `${url}/botperm.html`;
          const l_requestPermissionTitle = 'Notification - Request Permission';
          const l_requestPermissionParams = 'scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' + popupWinWidth + ',height=' + popupWinHeight + ',top=' + top + ',left=' + left;
          const l_requestPermissionFrame = window.open(l_requestPermissionUrl, l_requestPermissionTitle, l_requestPermissionParams);
          l_requestPermissionFrame.focus();

          l_requestPermissionFrame.onunload = function(evt) {
            if (evt.originalTarget) {
              //Firefox
              const original_url = evt.originalTarget.URL;
              if (original_url === 'about:blank'){
                //ignore it, window not closed
                return;
              }
            } else if( evt.target ) {
              if (evt.target.URL && ( typeof evt.target.URL === "string" )) {
                const original_url = evt.target.URL;
                if (original_url === 'about:blank') {
                  //ignore it, window not closed
                  return;
                }
              }
            }

            setTimeout(() => {
              if (l_requestPermissionFrame.closed) {
                if (Notification.permission === 'granted'){
                  _doRegistration()
                  .then((req) => resolve(true))
                  .catch((err) => reject(err));
                } else {
                  console.warn('Notifications not allowed by user (iframe)!');
                  resolve(false);
                }
              } else {
                console.warn('Popup not closed!');
              }
            }, 500 );
          };
        });
      } else {
        return new Promise((resolve, reject) => {
          Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              _doRegistration()
              .then((req) => resolve(true))
              .catch((err) => reject(err));
            } else {
              console.warn('Notifications not allowed by user!');
              reject(new Error("Notifications not allowed by user!"));
            }
          });
        });
      }
    }
  };

  /**
   * Step three: Create a subscription. Contact the third party push server (for
   * example mozilla's push server) and generate a unique subscription for the
   * current browser.
   */
  const subscribe = () => {
    console.info('Subscribe notifications.');

    if (!('serviceWorker' in navigator)) {
      console.warn('serviceWorker aren\'t supported.');
      return;
    }

    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      // Contact the third party push server. Which one is contacted by
      // pushManager is configured internally in the browser, so we don't
      // need to worry about browser differences here.
      //
      // When .subscribe() is invoked, a notification will be shown in the
      // user's browser, asking the user to accept push notifications from
      // <yoursite.com>. This is why it is async and requires a catch.
      serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: _publicSigningKey
      }).then((subscription) => {
          // Update the server state with the new subscription
          return sendSubscriptionToServer(subscription);
      }).catch((e) => {
        if (Notification.permission === 'denied') {
          console.warn('Permission for Notifications was denied');
        } else {
          console.error('Unable to subscribe to push.', e);
        }
      });
    }).catch(function( err ) {
      console.warn('Error during subscribe ready()', err);
    });
  };

  /**
   * Step four: Send the generated subscription object to our server.
   */
  const sendSubscriptionToServer = (subscription) => {

    console.info('Send subscription to server.');

    // Get public key and user auth from the subscription object
    const key = subscription.getKey ? subscription.getKey('p256dh') : '';
    const auth = subscription.getKey ? subscription.getKey('auth') : '';

    // This example uses the new fetch API. This is not supported in all
    // browsers yet.
    return fetch( `${_kbotUrl}/v1/notifications/subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          endpoint: subscription.endpoint,
          // Take byte[] and turn it into a base64 encoded string suitable for
          // POSTing to a server over HTTP
          key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : '',
          auth: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : ''
        })
    });
  };

  const init = function(p_kbotUrl) {
    if (isInIframe()) {
      console.log("App is running in frame!");
    }

    _kbotUrl = p_kbotUrl;

    if (!isSupported()) {
      return false;
    }

    navigator.serviceWorker.register(botSwPath)
    .then((req) => {
      updateSubscription();
      req.addEventListener('install', function(event){
        this.skipWaiting();
      });
      req.update();
    }).catch((err) => {
      console.warn('ServiceWorker not registered.', err);
    });
  };

  const unsubscribePushNotifications = (cb) => {
    if (!isSupported()) return;
    checkSubscription((flag) => {
      if (flag) {
        deregister((flag) => {
          cb && cb();
        });
      }
    });
  };

  const subscribePushNotifications = (cb) => {
    if (!isSupported()) return;

    checkSubscription((flag) => {
      if (!flag) {
        register().then((flag) => {
          if (flag){
            cb && cb(true);
          } else {
            cb && cb(false);
          }
        }).catch((err) => console.log("Some Error!", err));
      }
    });
  }

  return {
    init,
    sendSubscriptionToServer,
    subscribe,
    initialiseState,
    deregister,
    register,
    updateSubscription,
    checkSubscription,
    isInIframe,
    isSupported,
    unsubscribePushNotifications,
    subscribePushNotifications
  }
};

export default Notifications;