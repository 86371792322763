
import styled from 'styled-components';

const getUiStyle = (style) => {
  return styled.div`
    min-height: 100%;
    & .kbot-container {
      background: url(${style['background-image']});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    & .kbot-container .kbot-card .ant-card-body .options-container .ant-btn {
      background-color: ${style['select-option-background-color']};
      border-color: ${style['select-option-background-color']};
      color: ${style['select-option-text-color']};
    }

    & .kbot-container .kbot-card .ant-card-body .options-container .ant-btn:focus {
      border-color: red !important;
    }

    & .kbot-container .kbot-card .ant-card-body .options-container .ant-btn:hover {
      background-color: ${style['select-option-hover-background-color']};
      border-color: ${style['select-option-hover-text-color']};
      color: ${style['select-option-hover-text-color']};
    }

    & .kbot-container div.ant-list :last-of-type .ant-pagination-simple li.ant-pagination-next button {
      background-color: ${style['more-results-arrow-color']} !important;
      border-color: ${style['more-results-arrow-color']};

    }

    & .kbot-container div.ant-list :last-of-type .ant-pagination-simple li.ant-pagination-next button:hover {
      background-color: ${style['select-option-hover-background-color']} !important;
      border-color: ${style['select-option-hover-text-color']};
      color: ${style['select-option-hover-text-color']};
    }

    & .kbot-container div.ant-list :last-of-type .ant-pagination-simple li.ant-pagination-next button:focus {
      border-color: red !important;
    }

    & .kbot-container div.ant-list :first-of-type .ant-pagination-simple li.ant-pagination-prev button {
      background-color: ${style['more-results-arrow-color']} !important;
      border-color: ${style['more-results-arrow-color']};

    }

    & .kbot-container div.ant-list :first-of-type .ant-pagination-simple li.ant-pagination-prev button:hover {
      background-color: ${style['select-option-hover-background-color']} !important;
      border-color: ${style['select-option-hover-text-color']};
      color: ${style['select-option-hover-text-color']};

    }

    & .kbot-container div.ant-list :first-of-type .ant-pagination-simple li.ant-pagination-prev button:focus {
      border-color: red !important;
    }

    & .kbot-container {
      background-color: ${style['background-color']};
    }

    & .kbot-container .kbot-card .ant-card-head {
      background-color: ${style['header-background-color']};
    }

    & .kbot-container .kbot-card .ant-card-head .bot-title-text {
      color: ${style['header-text-color']};
    }

    & .kbot-container .kbot-card .ant-card-body .message.bot {
      background-color: ${style['bot-message-bubble-color']};
      color: ${style['bot-message-text-color']} !important;
    }

    & .kbot-container .kbot-card .ant-card-body .message.user {
      background-color: ${style['user-message-bubble-color']};
      color: ${style['user-message-text-color']} !important;
    }

    & .kbot-container .kbot-card .ant-card-body .message-holder .ant-btn {
      background-color: ${style['bubble-link-background-color']} !important;
      border-color: ${style['bubble-link-background-color']} !important;
      color: ${style['bubble-link-text-color']};
    }

    & .kbot-container .kbot-card .ant-card-body .message-holder .ant-btn.ant-btn-link {
      background-color: transparent !important;
      border-color: transparent !important;
      color: transparent !important;
    }

    & .kbot-container .kbot-card .ant-card-body .message-holder .ant-btn:focus {
      border-color: red !important;
    }

  `;
}

export default getUiStyle;
