
import axios from 'axios';
import { NativeMessagingService } from '@kcenter/kfirst-selfhealing-clientlib';
import { API_URL } from '../config/settings';
import store from '../config/store';

axios.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem('token')}`;
const { dispatch } = store;

export const UseAction = (options) => {
  const {
    normalAction,
    action,
    url,
    method,
    reqPayload,
    payload,
    cb,
  } = options;

  if (normalAction) {
    dispatch(action(payload));
    cb && cb();
    return;
  }

  const fullurl = `${API_URL}${url}`;

  let req ;

  if (method === 'post' || method === 'put'){
    req = axios[method](fullurl, reqPayload)
  } else {
    req = axios[method](fullurl)
  }
  req.then((response) => {
    // dispatch data to reducer
    dispatch(action(response.data || payload));
    // if callback function exists then pass the response to it and call it.
    cb && cb(response);
  })
  .catch((error) => {
    cb && cb(error, true);
  });
};

export const NativeMessagingAction = (options) => {
  const {
    action,
    reqPayload,
    cb
  } = options;

  NativeMessagingService.getInstance().executeCommand(reqPayload)
    .then((response) => {
      dispatch(action(response));
      cb && cb(response);
    })
    .catch(error => {
      console.error('Error during Native Messaging', error);
      cb && cb(error, true);
    });
};