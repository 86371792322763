import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import './index.less';
import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
export const init  = (config) => {
  ReactDOM.render(<React.StrictMode>
    <ConfigProvider getPopupContainer={() => document.getElementById('kbot-card')}>
      <App config={config} />
    </ConfigProvider>
  </React.StrictMode>, document.getElementById(config.selector));
};

// const testConfig = {
//   baseURL: 'http://localhost:38080/',
//   title: "USU KCenter Preview Agent",
//   key: "kcpreview",
//   url: "/kbot-api/interact/kcpreview/widget:Default",
//   useDocRenderer: true,
//   showAvatar: false,
//   avatarSource: ".\/preview\/kcpreview\/avatar",
//   _supportedLanguages: ["de","en"],
//   _optionDisplayCount: 3,
//   _allowAutocomplete: false,
//   _allowSubscriptions: false,
//   _showHelpIcon: false,
//   _helpDocGuid: "",
//   chatEnabled: true,
//   enableTracking: false,
//   botSwPath: "sw.js",
//   chatTranslations: {"de":{"AGENT_PENDING":"Warte auf live chat agent...","AGENT_CONNECTED":"Verbindung zum live chat wurde hergestellt.","AGENT_USERCLOSED":"Sie haben die Verbindung zum live chat beendet.","AGENT_NOTAVAILABLE":"Live chat ist gerade nicht erreichbar.","AGENT_CLOSED":"Verbindung zum live chat wurde geschlossen."},"en":{"AGENT_PENDING":"Live agent pending.","AGENT_CONNECTED":"Live agent is now connected.","AGENT_USERCLOSED":"You ended your chat with the live agent.","AGENT_NOTAVAILABLE":"Live agent is not available right now.","AGENT_CLOSED":"Live agent is now closed."}},
//   chatUrl: "https://chatbotcon.replyone.com:8444/chat/",
//   chatType: "sematell",
//   chatSystemId: "da8c8bac-4f79-474c-8f84-8a6d518ada22",
//   chatTenantId: "050f8c02-6f91-3f13-890e-5aa2ab96dfee",
//   chatChannelId: "109808ec-d16e-4523-a2cb-31db9717c32a",
//   chat: null
// };

// ReactDOM.render(<React.StrictMode>
//   <App config={testConfig} />
// </React.StrictMode>, document.getElementById('kbot-root'));


// export default {
//   widgets: {
//      myWidget: {
//         render: (args) => {
//             ReactDOM.render(
//               <React.StrictMode>
//                 <App />
//               </React.StrictMode>,
//               document.getElementById('root')
//             );
//         }
//      }
//   }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
