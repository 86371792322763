import { Space, Avatar } from "antd";
import Delayed from "./delayed";
import Audio from "../audio";

const MessageWrapper = (props) => {
  const {
    children,
    message,
    targetDelay,
    index,
    renderDebugPopover,
    renderFeedbackIcon,
    config,
    onComplete
  } = props;
  return (
    <Delayed waitBeforeShow={targetDelay} config={config} message={message} onComplete={onComplete} >
      <div className="message-container">
        {
          config && config.showAvatar && message.type === 'bot' && (
            <Avatar src={`${config.baseURL}kbot-widget/bots${config.avatarSource.replace('.', '')}`} />
          )
        }
        <div className={`message-holder ${message.type}`} key={index}>

          <div className={`message ${message.type}`} >
            {children}
          </div>

          {renderDebugPopover(message)}
          {renderFeedbackIcon(message)}
        </div>
      </div>
    </Delayed>
  );
};

export default MessageWrapper;
