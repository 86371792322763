import React, { useState } from 'react';
import { Modal, Upload, Alert, message } from 'antd';
import './add-file-modal.less';
import { InboxOutlined } from '@ant-design/icons';

message.config({
  getContainer: () => document.getElementById('kbot-card')
});

const AddUploadModal = (props) => {
  const { uploadLimit, isUploadModalVisible, setIsUploadModalVisible, file, setFile } = props;
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState(false);

  const { Dragger } = Upload;

  const splitSizeString = (str) => {
    const [, ...arr] = str.match(/(\d*)([\s\S]*)/);
    return arr;
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 500);
  }

  const uploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    customRequest: dummyRequest,
    fileList: file ? [file] : [],
    beforeUpload: file => {
      const allowed = ['image/png', 'image/gif', 'image/jpeg', 'image/png', 'application/pdf', 'text/plain']
      const isAllowed = allowed.indexOf[file.type] !== -1;
      const uploadLimitString = splitSizeString(uploadLimit);
      setError(false);
      if (!isAllowed) {
        setError(`${file.name} is not allowed`);
      }

      try {
        switch (uploadLimitString[1]) {
          case 'KB':
            if (file.size > Number(uploadLimitString[0])) {
              setError(`The selected file exceeds the maximum upload size of ${uploadLimit}`);
            }
            break;
          case 'MB':
            if (file.size / 1024 / 1024 >  Number(uploadLimitString[0])) {
              setError(`The selected file exceeds the maximum upload size of ${uploadLimit}`);
            }
            break;
          case 'GB':
            if (file.size / 1024 / 1024 / 1024 >  Number(uploadLimitString[0])) {
              setError(`The selected file exceeds the maximum upload size of ${uploadLimit}`);
            }
            break;

        }
      } catch(err) {
        if (file.size / 1024 / 1024 > 3 ) {
          setError(`The selected file exceeds the maximum upload size of 3MB`);
        }
      }

      fileToBase64(file).then(function (value) {
        setFile({ data: value, name: file.name, size: file.size, type: file.type });
        let fileList = [file];
        setFileList(fileList);
      });
      return false;
    },

    onRemove() {
      setFile(false);
      setFileList([]);
      setError(false);
    }
  };

  const handleOk = () => {
    setIsUploadModalVisible(false);
    setError(false);
  };

  const handleCancel = () => {
    setFile(false);
    setFileList([]);
    setIsUploadModalVisible(false);
    setError(false);
  };

  const renderWarning = () => {
    if (error) {
      return <Alert message={error} type="warning" showIcon ></Alert>
    }
    return null;
  }

  return (
    <>
      <Modal
        title="Upload a file"
        wrapClassName="chatbot-upload-modal"
        visible={isUploadModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="OK"
        width="450px"
        centered={true}
        okButtonProps={{ disabled: error && true }}
      >
        <Dragger {...uploadProps} className="upload-area" accept="image/png, image/gif, image/jpeg, image/png, application/pdf, text/plain">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single upload of image/pdf/text files up to {uploadLimit}. Hover on the file listed below and click on the dust bin to delete it.
          </p>
        </Dragger>
        {error && <Alert className="upload-error" message={error} type="warning" showIcon ></Alert>}
      </Modal>
    </>
  )
};

export default AddUploadModal;
