import { EventSourcePolyfill } from 'event-source-polyfill/src/eventsource.min.js';

const Chat = (config) => {
  let {
    BotReducer: {
      config: {
        lang,
        key,
      },
      session_id: sessionId,
      liwest_conversation_id: liwestConversationId
    },
    sendAgentMessage,
    setAgentTyping,
    getTerm
 } = config;

  let connection;
  const addAgentMessage = (msg) => sendAgentMessage({content: msg, type: 'agent'});

  let eventSource;
  let closeCallback;

  let typingTimerHandle;

  const sendChatMessage = (p_message) => {
    if(p_message != null && p_message.trim() !== '') {

      console.log("Send chat message...");
      const payload =
      {
        "session": {
          "sessionId": sessionId,
          "liwestConversationId": liwestConversationId
        },
        "request": {
          "type": "message",
          "locale": lang,
          "payload": {
            "text": p_message
          }
        }
      }

      fetch( '/kbot-api/interact/' + encodeURIComponent(key)  + '/liwest/message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
      .then(response => response.json())
      .catch((error) => {
        console.error('Error:', error);
        if(error && error.message) {
          addAgentMessage(error.message);
        }
      });
    }
  }

  const sendUserTypingEvent = () => {

    if(typingTimerHandle) {
      clearTimeout(typingTimerHandle);
    } else {
      sendUserTypingRequest("startTyping");
    }

    typingTimerHandle = setTimeout(() => {
      sendUserTypingRequest("endTyping");
      typingTimerHandle = undefined;
    }, 500);
  }

  const sendUserTypingRequest = (eventType) => {
    console.log("Send chat typing message...");
    const payload =
    {
      "session": {
        "sessionId": sessionId,
        "liwestConversationId": liwestConversationId
      },
      "request": {
        "type": "command",
        "locale": lang,
        "payload": {
          "type": eventType
        }
      }
    }

    fetch( '/kbot-api/interact/' + encodeURIComponent(key)  + '/liwest/message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .catch((error) => {
      console.error('Error:', error);
      if(error && error.message) {
        addAgentMessage(error.message);
      }
    });
  }

  const init = (initialLang, initSessionID, cb) => {
    lang = initialLang;
    sessionId = initSessionID;

    closeCallback = cb;

    const payload =
    {
      "session": {
        "sessionId": sessionId
      },
      "request": {
        "type": "command",
        "locale": lang,
        "payload": {
          "type": "startConversation"
        }
      }
    }

    console.log("Init chat");
    fetch('/kbot-api/interact/' + encodeURIComponent(key)  + '/liwest/message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.success) {

        if(data.liwestConversationId) {
          connection = true;
          liwestConversationId = data.liwestConversationId;
          addAgentMessage(getTerm('AGENT_CONNECTED', lang));
          registerSseEventListener(data);
        } else {
          console.error('Liwest conversation id is missing!');
          addAgentMessage(getTerm('UNEXPECTED ERROR', lang));
          closeCallback && closeCallback(getTerm('UNEXPECTED ERROR', lang));
        }

      } else {

        if(data.type === 'NO_CHATAGENT_AVAILABLE') {
          addAgentMessage(getTerm('AGENT_NOTAVAILABLE', lang));
          closeCallback && closeCallback(getTerm('AGENT_NOTAVAILABLE', lang));
        } else if(data.type === 'CONNECTOR_DISABLED') {
          addAgentMessage(getTerm('CONNECTOR_DISABLED', lang));
          closeCallback && closeCallback(getTerm('CONNECTOR_DISABLED', lang));
        } else if(data.type === 'FAILED_TO_SENT_MESSAGE') {
          addAgentMessage(getTerm('FAILED_TO_SENT_MESSAGE', lang));
          closeCallback && closeCallback(getTerm('FAILED_TO_SENT_MESSAGE', lang));
        } else {
          addAgentMessage(getTerm('UNEXPECTED ERROR', lang));
          closeCallback && closeCallback(getTerm('UNEXPECTED ERROR', lang));
        }

      }
    })
    .catch((error) => {
      console.error('Error:', error);
      addAgentMessage(getTerm('UNEXPECTED ERROR', lang));
      closeCallback && closeCallback(getTerm('UNEXPECTED ERROR', lang));
    });
  };

  const sendChatCloseMessage = () => {
    console.log("Send chat close message...");
    const payload =
    {
      "session": {
        "sessionId": sessionId,
        "liwestConversationId": liwestConversationId
      },
      "request": {
        "type": "command",
        "locale": lang,
        "payload": {
          "type": "closeConversation"
        }
      }
    }

    fetch( '/kbot-api/interact/' + encodeURIComponent(key)  + '/liwest/message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  const sendMessageReceivedEvent = (messageTimestamp) => {
    const payload =
    {
      "session": {
        "sessionId": sessionId,
        "liwestConversationId": liwestConversationId
      },
      "request": {
        "type": "command",
        "locale": lang,
        "payload": {
          "type": "messageReceived",
          "messageTimestamp": messageTimestamp
        }
      }
    }

    fetch( '/kbot-api/interact/' + encodeURIComponent(key)  + '/liwest/message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  const registerSseEventListener = (data) => {
    console.log("Register to SSE");

    eventSource = new EventSourcePolyfill('/kbot-api/interact/' + encodeURIComponent(key)  + '/liwest/stream-sse', {
      headers: {
        'x-kbot-session-id': sessionId,
        'x-liwest-conversation-id': liwestConversationId
      }
    });

    eventSource.onopen = function(event) {
      console.info("Connected");
      console.info(event);
    }

    eventSource.onerror = function(event) {
      console.error(event);
    }

    eventSource.onmessage = function(event) {
      console.trace("message: " + event.data);
      const messageObj = JSON.parse(event.data);

      if(messageObj && messageObj.success) {

        if(messageObj.type === 'SESSION_CLOSED_BY_AGENT') {
          sendMessageReceivedEvent(messageObj.timestamp);

          addAgentMessage(getTerm('AGENT_CLOSED', lang));
          closeCallback && closeCallback(getTerm('AGENT_CLOSED', lang));

          if(eventSource) {
            eventSource.close();
          }

          connection = false;
        } else if(messageObj.type === 'CHATAGENT_START_TYPING') {
          setAgentTyping(true);
          setTimeout(() => {
            setAgentTyping(false); // Hide after 30s, so it doesn't stay forever.
          }, 30000);
        } else if(messageObj.type === 'CHATAGENT_END_TYPING') {
          setAgentTyping(false);
        } else if(messageObj.type === 'PING_MESSAGE') {
          // Ping to keep SSE stream alive
          console.trace(messageObj.type);
        } else {
          sendMessageReceivedEvent(messageObj.timestamp);
          addAgentMessage(messageObj.message);
        }
      }
    }
  };

  const close = (userClose, cb) => {
    if (userClose) {
      addAgentMessage(getTerm('AGENT_USERCLOSED', lang));
      sendChatCloseMessage();
    }
    if(eventSource) {
      eventSource.close();
    }
    cb && cb();
    connection = false;
  };

  return {
    connection,
    sendChatMessage,
    sendUserTypingEvent,
    init,
    close
  };
};

export default Chat;