import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Alert } from 'antd';
import { connect } from 'react-redux';
import { sendFeedback } from '../../actions/bot-actions';
import './add-feedback-modal.less';

message.config({
  getContainer: () => document.getElementById('kbot-card')
});

const AddFeedbackModal = (props) => {
  const { isModalVisible, setIsModalVisible, SelectedMessage, default_language,  apiKey } = props;
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [ FeedbackForm ] = Form.useForm();
  const [latestFeedback, setLatestFeedback] = useState(false);

  const handleOk = () => {
    FeedbackForm.submit();
  };

  const handleCancel = () => {
    const feedback = FeedbackForm.getFieldValue('feedback');
    if (feedback && feedback !== '') {
      setIsModalConfirmVisible(true);
      setLatestFeedback(feedback);
    }
    setIsModalVisible(false);
  };

  const handleOkConfirm = () => {
    setIsModalConfirmVisible(false);
    setIsModalVisible(false);
    FeedbackForm.setFieldsValue({
      'feedback': ''
    });
    setLatestFeedback(false);
  };

  const handleCancelConfirm = () => {
    setIsModalConfirmVisible(false);
    setIsModalVisible(true);
    FeedbackForm.setFieldsValue({
      'feedback': latestFeedback
    });
  };

  const onFinish = (values) => {
    sendFeedback(
      values.feedback,
      SelectedMessage.metadata,
      default_language,
      apiKey,
      () => {
        setIsModalVisible(false);
        FeedbackForm.resetFields();
        message.success('Your feedback was submitted successfully');
      }
    );
  };

  useEffect(() => {
    if (!latestFeedback) {
      FeedbackForm.resetFields();
    }
  }, [isModalVisible]);

  return (
    <>
      <Modal
        wrapClassName="chatbot-feedback-modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Send"
        width="450px"
        centered={true}
      >
        <Form form={FeedbackForm} layout="vertical" name="control-hooks" onFinish={onFinish}>
          <Form.Item
            label="Content Quality Assurance"
            className="handle"
            name="feedback"
            rules={[{ required: true, message: 'Please fill out your feedback!' }]}>
            <Input.TextArea rows={4} placeholder='You can state your feedback on the moderation message / document and submit it to review.' />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        wrapClassName="chatbot-feedback-modal"
        visible={isModalConfirmVisible}
        onOk={handleOkConfirm}
        onCancel={handleCancelConfirm}
        okText="Discard"
        width="450px"
        centered={true}
      >
        <br />
        <Alert showIcon message="Are you sure to discard your feedback?" type="warning" />
        <br />
      </Modal>
    </>
  )
};

const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, {
  sendFeedback,
})(AddFeedbackModal);
