import { APPLICATION_ID, USER_ID } from '../config/settings';
import reducer from '../reducers/bot-reducer';
import { UseAction, NativeMessagingAction } from './base-actions';

const { actions } = reducer;
var API_URL = '';

export const setupURL = (url) => {
  API_URL = url;
};

export const getConfigAction = (data, cb) => UseAction({
  url: `${data.baseURL}kbot-widget/bots/${data.tenant}/${data.key}/settings`,
  method: 'get',
  action: actions.setConfig,
  cb
});

export const setConfigAction = (data, cb) => {
  return UseAction({
    normalAction: true,
    action: actions.setConfigNormal,
    payload: data,
    cb
  });
};

export const startSession = (options, cb) => {
  const {request_counter, session_id, default_language, vars, debug_mode} = options;
  const reqPayload = {
    "session" : {
        "application" : {
          "applicationId" : APPLICATION_ID
        },
        "user" : {
          "userId" : USER_ID
        },
        "attributes" : { },
        "new" : true
      },
      "request" : {
        "type" : "command",
        "requestId" : request_counter.toString(),
        "locale" : default_language,
        "debug": debug_mode || false,
        "timestamp" : new Date().toISOString(),
        "payload" : { "type" : "startSession" }
    }
  };

  if (session_id) {
    reqPayload.session["sessionId"] = session_id;
    reqPayload.session["new"] = false;
  }

  if (vars) {
    reqPayload.request.payload["vars"] = vars;
    if (vars.sessionId) {
      reqPayload.session["sessionId"] = vars.sessionId;
      reqPayload.session["new"] = false;
    }
  }


  return UseAction({
    url: API_URL,
    method: 'post',
    action: actions.startSession,
    reqPayload,
    cb
  });
};

export const resetSession = (options, cb) => {
  const {request_counter, session_id, default_language, vars, debug_mode, resetSource} = options;
  const reqPayload = {
    "session" : {
        "application" : {
          "applicationId" : APPLICATION_ID
        },
        "user" : {
          "userId" : USER_ID
        },
        "attributes" : { },
        "new" : false
      },
      "request" : {
        "type" : "command",
        "requestId" : request_counter.toString(),
        "locale" : default_language,
        "debug": debug_mode || false,
        "timestamp" : new Date().toISOString(),
        "payload" : { "type" : "resetSession", "resetsessionsource": resetSource }
    }
  };

  if (session_id) {
    reqPayload.session["sessionId"] = session_id;
    reqPayload.session["new"] = false;
  }

  if (vars) {
    let copyVars = {...vars};
    if (copyVars.query) {
      delete copyVars.query;
      reqPayload.request.payload["vars"] = copyVars;
    } else {
      reqPayload.request.payload["vars"] = vars;
    }

  }

  return UseAction({
    url: API_URL,
    method: 'post',
    action: actions.resetSession,
    reqPayload,
    cb
  });
};

export const sendCommand = (type, options, cb) => {
  const {request_counter, session_id, default_language, vars} = options;
  const reqPayload = {
    "session" : {
        "application" : {
          "applicationId" : APPLICATION_ID
        },
        "user" : {
          "userId" : USER_ID
        },
        "attributes" : { },
        "new" : true
      },
      "request" : {
        "type" : "command",
        "requestId" : request_counter.toString(),
        "locale" : default_language,
        "timestamp" : new Date().toISOString(),
        "payload" : { "type" : type }
    }
  };

  if (session_id) {
    reqPayload.session["sessionId"] = session_id;
    reqPayload.session["new"] = false;
  }

  if (vars) {
    reqPayload.request.payload["vars"] = vars;
  }

  return UseAction({
    url: API_URL,
    method: 'post',
    action: actions.runCommand,
    reqPayload,
    cb
  });
};

export const sendServerMessage = (message, options, chatSource, file, cb) => {
  const {request_counter, session_id, default_language, vars, user_id, debug_mode} = options;
  const reqPayload = {
    "session" : {
        "application" : {
          "applicationId" : APPLICATION_ID
        },
        "user" : {
          "userId" : user_id
        },
        "attributes" : { },
        "new" : true
      },
      "request" : {
        "type" : "message",
        "requestId" : request_counter.toString(),
        "locale" : default_language,
        "timestamp" : new Date().toISOString(),
        "payload" : {
          "text" : message
        },
        "debug": debug_mode
     }
  };

  if (chatSource) {
    reqPayload.request.payload['inputsource'] = chatSource;
  }

  if (session_id) {
    reqPayload.session["sessionId"] = session_id;
    reqPayload.session["new"] = false;
  }

  if (vars) {
    reqPayload.request.payload["vars"] = vars;
  }

  if (file && file.data) {
    reqPayload.request.payload["binary"] = file;
  }

  return UseAction({
    url: API_URL,
    method: 'post',
    action: actions.sendServerMsg,
    reqPayload,
    cb
  });
};

export const sendUserMessage = (message, cb) => {
  return UseAction({
    normalAction: true,
    action: actions.sendUserMsg,
    payload: { type: 'user', content: message },
    cb
  });
};

export const changeLanguage = (language, cb) => {
  return UseAction({
    normalAction: true,
    action: actions.changeLanguage,
    payload: language,
    cb
  });
};

export const startDebugMode = (cb) => {
  return UseAction({
    normalAction: true,
    action: actions.startDebug,
    payload: true,
    cb
  });
};

export const toggleDebugMode = (cb) => {
  return UseAction({
    normalAction: true,
    action: actions.toggleDebug,
    payload: null,
    cb
  });
};

export const generateVoiceForText = (text, cb) => {
  let url = `${API_URL}/convertTextToSpeech`;
  return UseAction({
    url:  url,
    method: 'post',
    action: actions.convertTextToSpeech,
    reqPayload: {'text': text},
    cb
  });
}

export const sendFeedback = (feedback, metadata, language, apiKey, cb) => {
  let feedbackUrl, payload;
  if (metadata.docid) {
    feedbackUrl = `${API_URL}/v2/documents?apikey=${encodeURIComponent(apiKey)}` ;
    payload = {'id': metadata.docid, 'message': feedback};
  } else {
    feedbackUrl = `${API_URL}/feedback?ag=${metadata.agentId}&mt=${metadata.messageType}&mid=${metadata.messageId}&lang=${language}`;
    payload = {'message': feedback};
  }

  return UseAction({
    url: feedbackUrl,
    method: 'post',
    action: actions.sendFeedback,
    reqPayload: payload,
    cb
  });
};


export const startLivechat = (defaultHint, cb) => {
  return UseAction({
    normalAction: true,
    action: actions.startLiveChat,
    payload: defaultHint,
    cb
  });
};

export const closeLivechat = (cb) => {
  return UseAction({
    normalAction: true,
    action: actions.closeLiveChat,
    payload: null,
    cb
  });
};

export const sendAgentMessage = (data, cb) => {
  return UseAction({
    normalAction: true,
    action: actions.sendAgentMsg,
    payload: data,
    cb
  });
};


export const loadDocument = (url, cb) => {
  return UseAction({
    url: `${url}`,
    method: 'get',
    action: actions.loadDocument,
    cb
  });
};

export const executeNativeMessage = (shCommand, cb) => {
  return NativeMessagingAction({
    action: actions.executeNativeMessage,
    reqPayload: shCommand,
    cb
  });
};